import { useState, useRef, FormEvent } from "react";
import { ObjectUtils } from "../../utils";
import { BenefitTooltipDesc, FormComponent, ModalComponent } from "../../components";
import { AddNewDependentForm, AddNewDependentInfoError } from "../../interfaces/formValidation";
import { AddDependent, dissmiss, getDependents, setNavigationError } from "../../actions";
import { useDispatch } from "react-redux";

const AddNewDependent = (props: any) => {
  const dispatch = useDispatch();
  const textInputRefs = useRef<HTMLInputElement[]>([]);
  const { contents, contents1, dependents, contentFooter, closeAddNewDependentModel } = props || {};
  const {
    genderList,
    relationshipList,
    smokerStatusList,
    tooltipCoveredThisPlan,
    tooltipCoveredOtherPlan,
    isAllowUpdateSudentStatus,
    spousalSmokerDeclarationOptional,
    canSpouseAdded,
    isCoveredOtherPlanEditable,
    isCoveredThisPlanEditable,
    validationforFirstName,
    validationforLastName,
    validationforInitial,
    smokerDeclarationLabel
  } = dependents || {};

  const [addNewDependent, setAddNewDependent] = useState<AddNewDependentForm>({
    formValidated: false,
    firstName: "",
    lastName: "",
    initial: "",
    dateOfBirth: {
      day: "",
      month: "",
      year: ""
    },
    gender: "",
    smokerStatus: 2,
    status: 0,
    relationshipID: parseInt(relationshipList[0].value),
    dependentStatus: 0,
    isCoveredThisPlan: true,
    isCoveredOtherPlan: false,
    hasDeclaredSmokerStatus: true,
    isStudentStatusConfirmed: false,
    isOverageFullTimeStudent: false
  });
  const [showToolTipDesc, setToolTipDesc] = useState<BenefitTooltipDesc>({
    showDesc: false,
    description: "",
    toolTipId: "",
    toolTipHeader: ""
  });
  const [studentDeclarationState, setStudentDeclarationState] = useState<any>(0);
  const [checkDOB, setCheckDOB] = useState<boolean>(false);
  const [infoErrorMessage, setinfoErrorMessage] = useState<AddNewDependentInfoError>({
    firstNameError: "",
    lastNameError: "",
    initialError: "",
    formErrors: "",
    isValid: true
  });

  // const [displayError, setDisplayError] = useState<boolean>(false);
  const lblFirstNameText = ObjectUtils.extractLabelFromObject(contents, "lblFirstNameText");
  const lblDepSmokerText = ObjectUtils.extractLabelFromObject(contents, "lblDepSmokerText");
  const lblLastNameText = ObjectUtils.extractLabelFromObject(contents, "lblLastNameText");
  const lblCoveredThisPlanCaptionText = ObjectUtils.extractLabelFromObject(contents, "lblCoveredThisPlanCaptionText");
  const lblCoveredOtherPlanCaptionText = ObjectUtils.extractLabelFromObject(contents, "lblCoveredOtherPlanCaptionText");
  const lblDepedendentFirstNamePlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblDepedendentFirstNamePlaceHolder");
  const lblDependentLastNamePlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblDependentLastNamePlaceHolder");
  const lblDependentInitialPlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblDependentInitialPlaceHolder");

  const lblRelationshipText = ObjectUtils.extractLabelFromObject(contents, "lblRelationshipText");
  const lblYes = ObjectUtils.extractLabelFromObject((contentFooter?.contentResource && contentFooter?.contentResource.contents) || {}, "lblYes");
  const lblNo = ObjectUtils.extractLabelFromObject((contentFooter?.contentResource && contentFooter?.contentResource.contents) || {}, "lblNo");
  const toolTipIcon = ObjectUtils.extractLabelFromObject((contentFooter?.contentResource && contentFooter?.contentResource.contents) || {}, "ToolTipIcon");
  const lblStudentDeclarationText = ObjectUtils.extractLabelFromObject(contents, "lblStudentDeclarationText");
  const lblDatePlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblDatePlaceHolder");
  const lblBirthDateText = ObjectUtils.extractLabelFromObject(contents, "lblBirthDateText");
  const lblGenderText = ObjectUtils.extractLabelFromObject(contents, "lblGenderText");
  const effectiveDate = ObjectUtils.extractLabelFromObject(contents, "effectiveDate");
  const isEffectiveDate = contents.findIndex((item: any) => item.keyName == "effectiveDate") === -1 ? false : true;
  const lblEffectiveDateText = ObjectUtils.extractLabelFromObject(contents, "lblEffectiveDateText");
  const lblStudentNotAllowedText = ObjectUtils.extractLabelFromObject(contents, "lblStudentNotAllowedText");

  const isValidStudent = relationshipList?.filter((val) => val.type === "Student" && val.value === addNewDependent?.relationshipID?.toString());

  const studentDeclarationYes = [
    { text: lblYes.display, value: 1 },
    { text: lblNo.display, value: 2 }
  ];

  const childCanTurnStudent = [{ text: `<span class="ml-mandatory-sign">*</span>${lblStudentDeclarationText.display}`, value: true }];

  const spouseList: string[] = [];

  relationshipList.map((item: any) => {
    if (item.type === "Spouse") {
      spouseList.push(item.value);
    }
    return null;
  });

  /*Form Validation Message Starts Here*/
  const rfvFirstNameErrorMessage = ObjectUtils.extractLabelFromObject(contents, "rfvFirstNameErrorMessage");
  const rfvLastNameErrorMessage = ObjectUtils.extractLabelFromObject(contents, "rfvLastNameErrorMessage");
  const lblBirthDateRequiredText = ObjectUtils.extractLabelFromObject(contents, "lblBirthDateRequiredText");
  const cmpGenderErrorMessage = ObjectUtils.extractLabelFromObject(contents, "cmpGenderErrorMessage");
  const custDepSmokerErrorMessage = ObjectUtils.extractLabelFromObject(contents, "custDepSmokerErrorMessage");
  const custStudentErrorMessage = ObjectUtils.extractLabelFromObject(contents, "custStudentErrorMessage");
  const regLastNameErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regLastNameErrorMessage").display;
  const regFirstNameErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regFirstNameErrorMessage").display;
  const regMiddleInitialErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regInitialErrorMessage").display;
  const lblYouCantAddSpouse = ObjectUtils.extractLabelFromObject(contents, "lblYouCantAddSpouse");

  const handleDateChange = (eventDate: any) => {
    const dateOfBirth = { ...eventDate };

    if (dateOfBirth.year === "NaN" || dateOfBirth.year === "") {
      dateOfBirth.day = null;
      dateOfBirth.month = null;
      dateOfBirth.year = null;

      setCheckDOB(true);
    } else {
      setCheckDOB(false);
    }

    eventDate = dateOfBirth;
    setAddNewDependent({
      ...addNewDependent,
      dateOfBirth: eventDate
    });
  };

  const handleSubmit = (event: FormEvent<EventTarget>): void => {
    event.preventDefault();
    dissmiss();
    if (!isValidation()) {
      const dependent = { ...addNewDependent };
      addNewDependent["fullName"] = `${addNewDependent.firstName} ${addNewDependent.lastName}`;
      dispatch(
        AddDependent(
          dependent,
          (response) => {
            getDependents("");
            closeAddNewDependentModel();
            setNavigationError(null);
            console.log(response);
          },
          (errorMessage) => {
            setinfoErrorMessage(errorMessage);
          }
        )
      );
    }
  };

  const btnSave = ObjectUtils.extractLabelFromObject(contents1, "btnSave");
  const btnCancel = ObjectUtils.extractLabelFromObject(contents1, "btnCancel");

  const formInput = {
    classname: "loginform",
    validated: addNewDependent.formValidated,
    handlesubmit: handleSubmit
  };

  const onInputChange = (value: any) => {
    isValidInfo(value);
    setAddNewDependent({
      ...addNewDependent,
      ...value
      //   formValidated: false
    });
  };

  const isValidInfo = (value: any) => {
    // const { validationforFirstName, validationforLastName, validationforInitial } = props || {};
    if (value.firstName !== undefined) {
      if (ObjectUtils.isTest(value?.firstName, validationforFirstName)) {
        infoErrorMessage.firstNameError = regFirstNameErrorMessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.firstNameError = "";
        infoErrorMessage.isValid = true;
      }
    }

    if (value.lastName !== undefined) {
      if (ObjectUtils.isTest(value?.lastName, validationforLastName)) {
        infoErrorMessage.lastNameError = regLastNameErrorMessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.lastNameError = "";
        infoErrorMessage.isValid = true;
      }
    }

    if (value.initial !== undefined) {
      if (ObjectUtils.isTest(value?.initial, validationforInitial)) {
        infoErrorMessage.initialError = regMiddleInitialErrorMessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.initialError = "";
        infoErrorMessage.isValid = true;
      }
    }
    setinfoErrorMessage(infoErrorMessage);
  };

  const handleSelection = (callback: any) => {
    const key: any = Object.keys(callback)[0];
    const value: any = Object.values(callback)[0];
    switch (key) {
      case "relationshipID":
        addNewDependent.relationshipID = parseInt(value);
        break;
      case "dependentGender":
        addNewDependent.gender = value;
        break;
      case "isStudentStatusConfirmed":
        addNewDependent.isStudentStatusConfirmed = value;
        break;
      case "isCoveredThisPlan":
        addNewDependent.isCoveredThisPlan = value;
        break;
      case "isCoveredOtherPlan":
        addNewDependent.isCoveredOtherPlan = value;
        break;
      case "smokerStatus":
        addNewDependent.smokerStatus = value;
        break;
      case "studentDeclarationState":
        addNewDependent.isOverageFullTimeStudent = parseInt(value) === 1 ? true : false;
        setStudentDeclarationState(value);
        break;
    }

    setAddNewDependent({
      ...addNewDependent
    });
  };

  const isValidation = () => {
    let isValid = false;
    setCheckDOB(addNewDependent?.dateOfBirth.year === null || addNewDependent?.dateOfBirth.year === "" || addNewDependent?.dateOfBirth.year === "NaN" || addNewDependent?.dateOfBirth.year.length < 4);

    if (!addNewDependent.firstName) {
      setAddNewDependent({
        ...addNewDependent,
        formValidated: true
      });
      textInputRefs.current[0]?.focus();
      isValid = true;
    } else if (!addNewDependent.lastName) {
      setAddNewDependent({
        ...addNewDependent,
        formValidated: true
      });
      textInputRefs.current[1]?.focus();
      isValid = true;
    } else if (addNewDependent.gender === "") {
      setAddNewDependent({
        ...addNewDependent,
        formValidated: true
      });
      isValid = true;
    } else if (spouseList.includes(addNewDependent?.relationshipID.toString()) && spousalSmokerDeclarationOptional === false && addNewDependent.smokerStatus === 2) {
      setAddNewDependent({
        ...addNewDependent,
        formValidated: true
      });
      isValid = true;
    } else if (checkDOB) {
      setAddNewDependent({
        ...addNewDependent,
        formValidated: true
      });
      isValid = true;
    }
    //  else if (isValidStudent?.length > 0 && isAllowUpdateSudentStatus && !addNewDependent.isStudentStatusConfirmed) {
    //   setAddNewDependent({
    //     ...addNewDependent,
    //     formValidated: true
    //   });
    //   isValid = true;
    // }
    else if (isValidStudent?.length > 0 && !isAllowUpdateSudentStatus && studentDeclarationState === 0) {
      setAddNewDependent({
        ...addNewDependent,
        formValidated: true
      });
      isValid = true;
    }

    return isValid;
  };

  const toggleDescription = (info: string, title: any) => {
    setToolTipDesc({ ...showToolTipDesc, showDesc: !showToolTipDesc?.showDesc, description: info, toolTipHeader: title?.display });
  };

  const handleCloseTooltip = () => {
    setToolTipDesc({ ...showToolTipDesc, showDesc: false, description: "", toolTipHeader: "" });
  };

  const buttonInput = [
    {
      variant: "outline-secondary btn-cancel",
      display: btnCancel.display,
      onClick: closeAddNewDependentModel
    },
    {
      type: "submit",
      variant: "outline-secondary btn-save",
      display: btnSave.display
    }
  ];

  const gridRowData = [
    /*this array object is for first name section*/
    [
      {
        type: "text",
        display: `${lblFirstNameText.display}<span class="ml-mandatory-sign">*</span> `,
        maxLength: 50,
        name: "firstName",
        labeltext: lblDepedendentFirstNamePlaceHolder?.display,
        texttoread: lblDepedendentFirstNamePlaceHolder?.textToRead,
        mode: "edit",
        colclassname: "col-sm-12 col-md-4",
        lblclassname: "edit-input-label mb-2",
        error: rfvFirstNameErrorMessage?.display,
        refInput: (ref: HTMLInputElement) => (textInputRefs.current[0] = ref),
        handlechange: onInputChange,
        required: true
      },
      {
        type: "text",
        display: lblDependentInitialPlaceHolder.display,
        texttoread: lblDependentInitialPlaceHolder?.textToRead,
        maxLength: 1,
        name: "initial",
        mode: "edit",
        colclassname: "col-sm-12 col-md-4",
        lblclassname: "edit-input-label mb-2",
        handlechange: onInputChange,
        required: false
      },
      {
        type: "text",
        display: `${lblLastNameText.display}<span class="ml-mandatory-sign">*</span> `,
        texttoread: lblDependentLastNamePlaceHolder?.textToRead,
        maxLength: 50,
        name: "lastName",
        mode: "edit",
        colclassname: "col-sm-12 col-md-4",
        lblclassname: "edit-input-label mb-2",
        error: rfvLastNameErrorMessage.display,
        handlechange: onInputChange,
        refInput: (ref: HTMLInputElement) => (textInputRefs.current[1] = ref),
        required: true
      }
    ],
    /* this section is for gender only*/
    [
      { rowclassname: "mt-3" },
      {
        type: "choicelist",
        colclassname: "ml-popupsection ml-newdependent",
        mode: "edit",
        options: {
          type: "radio",
          selected: addNewDependent.relationshipID || 2,
          name: "relationshipID",
          legendtext: lblRelationshipText.display,
          optionsinput: relationshipList || [],
          handlechange: handleSelection,
          width: "50",
          fgclass: "mt-2 mb-4 float-left"
        }
      }
    ],
    [
      isValidStudent?.length > 0 &&
        !isAllowUpdateSudentStatus && {
          type: "choicelist",
          colclassname: "ml-popupsection ml-newdependent",
          mode: "edit",
          options: {
            type: "radio",
            name: "studentDeclarationState",
            selected: studentDeclarationState,
            legendtext: `<span class="ml-mandatory-sign">*</span>${lblStudentDeclarationText.display} `,
            optionsinput: studentDeclarationYes,
            width: "50",
            fgclass: "float-left",
            error: studentDeclarationState === "2" ? lblStudentNotAllowedText.display : addNewDependent.formValidated && studentDeclarationState === 0 ? custStudentErrorMessage.display : "",
            errorclass: studentDeclarationState === "2" ? "clearfix d-block pb-3 ps-3" : addNewDependent.formValidated && studentDeclarationState === 0 ? "ml-error" : "clearfix d-block pb-3 ps-3",

            handlechange: handleSelection
          }
        },
      isValidStudent?.length > 0 &&
        isAllowUpdateSudentStatus && {
          type: "choicelist",
          colclassname: "ml-popupsection ml-newdependent",
          mode: "edit",
          options: {
            type: "checkbox",
            name: "isStudentStatusConfirmed",
            selected: addNewDependent.isStudentStatusConfirmed,
            ischecked: true,
            // legendtext: `${lblStudentDeclarationText.display}<span class="ml-mandatory-sign">*</span> `,
            optionsinput: childCanTurnStudent,
            error: studentDeclarationState === "2" ? lblStudentNotAllowedText.display : addNewDependent.formValidated && studentDeclarationState === 0 ? custStudentErrorMessage.display : "",
            handlechange: handleSelection,
            errorclass: studentDeclarationState === "2" ? "clearfix d-block pb-3 ps-3" : addNewDependent.formValidated && studentDeclarationState === 0 ? "ml-error" : "clearfix d-block pb-3 ps-3"
          }
        }
    ],
    canSpouseAdded && [
      {
        colclassname: "col-sm-12 col-md-12",
        classname: "info",
        type: "instructions",
        display: lblYouCantAddSpouse.display
      }
    ],
    [
      {
        colclassname: "col-sm-12 col-md-12 ml-popupsection position-relative",
        className: "ml-datecomponent",
        name: "dateOfBirth",
        type: "calendar",
        placeholder: lblDatePlaceHolder.display,
        display: `${lblBirthDateText.display}<span class="ml-mandatory-sign">*</span> `,
        texttoread: lblDatePlaceHolder.textToRead,
        mode: "edit",
        width: "w-50",
        date: addNewDependent?.dateOfBirth,
        isreadonly: "true",
        onChange: handleDateChange,
        wrapperclass: "ml-dateofbirth",
        errormessage: checkDOB ? lblBirthDateRequiredText.display : "",
        checkdob: checkDOB.toString(),
        formvalidated: addNewDependent.formValidated.toString()
      }
    ],
    [
      {
        type: "choicelist",
        colclassname: "ml-popupgender form-group",
        mode: "edit",
        options: {
          type: "radio",
          selected: addNewDependent.gender,
          name: "dependentGender",
          legendtext: `${lblGenderText.display}<span class="ml-mandatory-sign">*</span> `,
          optionsinput: genderList || [],
          handlechange: handleSelection,
          width: "50",
          fgclass: "mt-2 mb-2 float-left",
          error: addNewDependent.formValidated && !addNewDependent.gender && cmpGenderErrorMessage.display,
          errorclass: "ml-error"
        }
      }
    ],
    [
      spouseList.includes(addNewDependent?.relationshipID.toString()) &&
        spousalSmokerDeclarationOptional === false && {
          type: "choicelist",
          colclassname: "ml-popupgender form-group",
          mode: "edit",
          options: {
            type: "radio",
            selected: addNewDependent?.smokerStatus,
            name: "smokerStatus",
            legendtext: `${lblDepSmokerText.display}<span class="ml-mandatory-sign">*</span> `,
            optionsinput: smokerStatusList || [],
            handlechange: handleSelection,
            width: "50",
            fgclass: "float-left",
            error: addNewDependent.formValidated && addNewDependent.smokerStatus.toString() === "2" && custDepSmokerErrorMessage.display,
            errorclass: "ml-error"
          }
        },
      spouseList.includes(addNewDependent?.relationshipID.toString()) &&
        addNewDependent.smokerStatus.toString() === "0" && {
          colclassname: "col-sm-12 col-md-12",
          type: "instructions",
          display: smokerDeclarationLabel,
          classname: "primary mb-3 mt-3"
        }
    ],
    [
      isCoveredThisPlanEditable && {
        type: "choicelist",
        colclassname: "col-sm-12 col-md-6",
        mode: "edit",
        options: {
          type: "checkbox",
          ischecked: true,
          name: `isCoveredThisPlan`,
          selected: addNewDependent?.isCoveredThisPlan,
          optionsinput: [
            {
              text: lblCoveredThisPlanCaptionText.display,
              value: true
            }
          ],

          tooltipicon: tooltipCoveredThisPlan !== "" && {
            type: "tooltipicon",
            ariaLabel: toolTipIcon.textToRead,
            className: "tooltip-icon icon-action ms-2",
            onSubmit: () => toggleDescription(tooltipCoveredThisPlan, lblCoveredThisPlanCaptionText)
          },
          handlechange: handleSelection
        }
      },
      isCoveredOtherPlanEditable && {
        type: "choicelist",
        colclassname: "col-sm-12 col-md-6",
        mode: "edit",
        options: {
          type: "checkbox",
          ischecked: true,
          name: `isCoveredOtherPlan`,
          selected: addNewDependent?.isCoveredOtherPlan,
          optionsinput: [
            {
              text: lblCoveredOtherPlanCaptionText.display,
              value: true
            }
          ],
          tooltipicon: tooltipCoveredOtherPlan !== "" && {
            type: "tooltipicon",
            ariaLabel: toolTipIcon.textToRead,
            className: "tooltip-icon icon-action ms-2",
            onSubmit: () => toggleDescription(tooltipCoveredOtherPlan, lblCoveredOtherPlanCaptionText)
          },
          handlechange: handleSelection
        }
      }
    ],
    isEffectiveDate && [
      {
        type: "text",
        name: "effectiveDate",
        display: lblEffectiveDateText.display + " " + effectiveDate?.display,
        mode: "view",
        colclassname: "col-12 p-0",
        fgclass: "clearfix"
      }
    ]
  ];

  const modalObject = {
    showModal: showToolTipDesc?.showDesc,
    clickHandler: handleCloseTooltip,
    dialogClass: "infopopup",
    head: {
      modalTitle: showToolTipDesc?.showDesc && showToolTipDesc?.toolTipHeader
    },
    body: {
      confirmBoxData: {
        messagetext: showToolTipDesc?.description || "",
        messagetextclass: ""
      }
    }
  };

  return (
    <>
      <div className="formErrors">
        {infoErrorMessage &&
          Object.keys(infoErrorMessage).map((fieldName, i) => {
            if (infoErrorMessage[fieldName]?.length > 0) {
              return (
                <p className="formErrorPStyle" key={i} role="alert">
                  {infoErrorMessage[fieldName]}
                </p>
              );
            } else {
              return "";
            }
          })}
      </div>
      <FormComponent
        formdata={formInput}
        gridRowData={gridRowData}
        btndata={buttonInput}
        btnviewmode={1}
        btnviewclass="d-flex justify-content-between ml-popupbuttons border-top pt-3"
        rowclassname="mt-3"
      ></FormComponent>
      <ModalComponent modalObject={modalObject} />
    </>
  );
};

export default AddNewDependent;
