import { useCallback, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dissmiss, getTFARegContent, getTFARegSelection, getVerificationContent, logoff, sendVerificationCode, showError, showSuccess, validateTFACode } from "../../../actions";
import { ButtonComponent, ChoiceListComponent } from "../../../components";
import { RootState } from "../../../reducers";
import { ObjectUtils } from "../../../utils";
import SelectedOptionForm from "./SelectedOptionForm";
import VerificationForm from "./VerificationForm";

export const MFAAuthentication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [mfaIntialState, setMfaIntialState] = useState({
    multiFAList: {
      twoFATypeId: "",
      PhoneOrEmail: ""
    },
    formValidated: false,
    registrationStep: 0,
    resendCount: 0,
    nextScreen: true
  });

  const { needTwoFARegistration, needTwoFAValidation, verificationContent } = useSelector((state: RootState) => state.auth);
  const { twoFARegContent, regContent } = useSelector((state: RootState) => state.auth);
  const content = twoFARegContent?.contentResource?.contents;
  const { resendCodeInterval } = verificationContent;
  // const verifiedContent = verificationContent?.contentResource?.contents;
  const { twoFATypes: multiFAList = [], allowCancel } = twoFARegContent;

  const verficationStepHandler = useCallback(() => {
    const twoFATypeId = sessionStorage.getItem("twoFATypeID") || "";

    const options = {
      PhoneOrEmail: mfaIntialState.multiFAList.PhoneOrEmail,
      TypeID: twoFATypeId,
      isRegistration: false,
      resendCount: mfaIntialState.resendCount
    };
    sessionStorage.setItem("isRegistration", "false");

    handleVerification(options);
  }, []);

  useEffect(() => {
    if (!needTwoFAValidation) {
      navigate("/");
    }
    if (needTwoFARegistration) {
      sessionStorage.setItem("isRegistration", "true");
      setMfaIntialState((prevState) => ({
        ...prevState,
        registrationStep: 1
      }));
      dispatch(getTFARegContent(navigate));
    } else if (!needTwoFARegistration && needTwoFAValidation) {
      verficationStepHandler();
    }
  }, [dispatch, navigate, needTwoFARegistration, needTwoFAValidation, verficationStepHandler]);

  const fetchTFARegSelection = useCallback(async () => {
    const updatedMFAValue = multiFAList?.[0]?.value;

    if (multiFAList?.length === 1 && updatedMFAValue !== mfaIntialState?.multiFAList?.twoFATypeId) {
      setMfaIntialState({
        ...mfaIntialState,
        multiFAList: {
          twoFATypeId: updatedMFAValue,
          PhoneOrEmail: ""
        },
        registrationStep: 2
      });
      dispatch(await getTFARegSelection(updatedMFAValue, true, navigate));
    }
  }, [dispatch, mfaIntialState, multiFAList, navigate]);

  useEffect(() => {
    fetchTFARegSelection();
  }, [fetchTFARegSelection]);

  const handleSubmit = async (e?: any) => {
    e.preventDefault();

    if (!mfaIntialState?.multiFAList?.twoFATypeId) {
      setMfaIntialState({ ...mfaIntialState, formValidated: true });
    } else {
      setMfaIntialState({ ...mfaIntialState, registrationStep: 2 });
      dispatch(await getTFARegSelection(mfaIntialState?.multiFAList.twoFATypeId, true, navigate));
    }
  };

  const setValue = (callback: any) => {
    const value: any = Object.values(callback)[0];
    sessionStorage.setItem("twoFATypeID", value);
    setMfaIntialState({ ...mfaIntialState, multiFAList: { twoFATypeId: value, PhoneOrEmail: "" } });
  };

  const handleCancel = async () => {
    dispatch(await logoff());
  };

  const failureCallback = (msg: any) => {
    showError(msg ? msg : "Please enter a valid contact number.");
  };

  const handleVerification = async (options) => {
    const isRegistration = JSON.parse(sessionStorage.getItem("isRegistration") || "false");

    const PhoneOrEmail = options?.PhoneOrEmail || "";
    const twoFATypeId = options?.TypeID;
    const params = "isRegistration=" + isRegistration + "&selectedTwoFAType=" + options?.TypeID;

    setMfaIntialState((prevState) => ({
      ...prevState,
      registrationStep: 3,
      multiFAList: {
        ...prevState.multiFAList,
        twoFATypeId,
        PhoneOrEmail
      }
    }));

    if (needTwoFARegistration && regContent?.qrCodeLink) {
      dispatch(await validateTFACode(options, navigate, failureCallback));
    }

    if (!needTwoFARegistration && parseInt(options?.TypeID) === 4) {
      dispatch(getVerificationContent(params));
    } else if (needTwoFARegistration && parseInt(options?.TypeID) === 4 && multiFAList?.length > 1) {
      setMfaIntialState({ ...mfaIntialState, registrationStep: 2 });
      // dispatch(await getTFARegSelection(options?.TypeID, true, navigate));
    } else if (parseInt(options?.TypeID) === 3 || parseInt(options?.TypeID) === 2) {
      dispatch(
        await sendVerificationCode(
          options,
          (isCodeSend) => {
            if (!isCodeSend) {
              setMfaIntialState((prevState) => ({
                ...prevState,
                registrationStep: 2
              }));
              handleSubmit();
            } else {
              dissmiss();
              setMfaIntialState((prevState) => ({
                ...prevState,
                registrationStep: 3
              }));
              if (options?.resendCount > 0) {
                showSuccess("Verification code resent successfully.");
              }
              dispatch(getVerificationContent(params));
            }
          },
          (errorMessage) => {
            failureCallback(errorMessage);
            setMfaIntialState((prevState) => ({
              ...prevState,
              registrationStep: 2
            }));
            handleSubmit;
          }
        )
      );
    }
  };

  const handleResend = () => {
    const totalCount = mfaIntialState?.resendCount + 1;
    setMfaIntialState({ ...mfaIntialState, resendCount: totalCount });
    const { twoFATypeId } = mfaIntialState?.multiFAList;

    const options = {
      PhoneOrEmail: parseInt(twoFATypeId) === 2 ? mfaIntialState?.multiFAList?.PhoneOrEmail : "",
      TypeID: parseInt(twoFATypeId),
      isRegistration: true,
      resendCount: totalCount
    };
    handleVerification(options);
  };

  const renderStep1 = () => {
    const lblDetail1 = ObjectUtils.extractLabelFromObject(content, "lblDetail1");
    const lblDetail2 = ObjectUtils.extractLabelFromObject(content, "lblDetail2");
    const lblTitle = ObjectUtils.extractLabelFromObject(content, "lblTitle");
    const lblSubmit = ObjectUtils.extractLabelFromObject(content, "btnSubmit");
    const lblCancel = ObjectUtils.extractLabelFromObject(content, "btnCancel");
    document.title = lblTitle?.display;

    const mfaListdata = {
      component: "mfa",
      name: "mfa",
      width: 100,
      fgclass: "mt-2 mb-4 float-left",
      selected: mfaIntialState?.multiFAList?.twoFATypeId,
      legendtext: `${lblDetail1.display}`,
      handlechange: setValue,
      optionsinput: multiFAList
    };

    return content ? (
      <div className="conatiner" role="main" id="maincontent">
        <div className="login-holder">
          <Row className="h-100">
            <div className="logincontainer d-md-flex align-items-center align-content-center justify-content-between">
              <div className="holder login-form description panel d-none d-md-block">
                <span dangerouslySetInnerHTML={{ __html: lblDetail2.display }} />
              </div>

              <div className="form-group login-form mobile-login-form pb-5 pb-md-0">
                <h1 className="login-heading d-md-block">{lblTitle.display}</h1>
                <div className="login-form description panel d-md-none">
                  <span dangerouslySetInnerHTML={{ __html: lblDetail2.display }} />
                </div>
                <div className="holder">
                  <Form className="loginform" noValidate validated={mfaIntialState?.formValidated} onSubmit={handleSubmit}>
                    <Row>
                      <Col className="col-12 paddingbottom">
                        <ChoiceListComponent choicelistdata={mfaListdata} />
                      </Col>
                      <Col>
                        <div className={`btns-forgot d-flex justify-content-between`}>
                          {allowCancel && (
                            <ButtonComponent
                              btndata={[
                                {
                                  variant: "outline-secondary",
                                  display: lblCancel?.display,
                                  onSubmit: handleCancel
                                }
                              ]}
                            />
                          )}

                          <ButtonComponent
                            btndata={[
                              {
                                className: "secondary",
                                display: lblSubmit?.display,
                                type: "submit",
                                disabled: mfaIntialState?.multiFAList.twoFATypeId ? false : true
                              }
                            ]}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </div>
    ) : null;
  };

  const renderStep2 = () => {
    const hasRegContent = Object.keys(regContent).length > 0;
    const option = parseInt(mfaIntialState.multiFAList.twoFATypeId);
    return (
      hasRegContent &&
      (option === 2 || option === 3 || option === 4 ? (
        <SelectedOptionForm
          //
          regContent={regContent}
          verificationCbHandler={handleVerification}
        />
      ) : null)
    );
  };

  const renderStep3 = () => {
    const hasVerifiedContent = verificationContent && Object.keys(verificationContent).length > 0;
    // const option = parseInt(mfaIntialState.multiFAList.twoFATypeId);

    return hasVerifiedContent ? (
      <>
        <VerificationForm
          //
          verifiedContent={verificationContent}
          resendCodeInterval={resendCodeInterval}
          resendCount={mfaIntialState?.resendCount}
          handleResend={handleResend}
        />
      </>
    ) : null;
  };

  const mfaScreenHandler = () => {
    switch (mfaIntialState?.registrationStep) {
      case 1:
        return renderStep1();
      case 2:
        return renderStep2();
      case 3:
        return renderStep3();
      default:
        return renderStep1();
    }
  };

  return <>{mfaScreenHandler()}</>;
};

export default MFAAuthentication;
