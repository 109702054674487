import { Col, Form, Row } from "react-bootstrap";
import { ButtonComponent } from "../../../components";
import { ObjectUtils } from "../../../utils";
import { useState } from "react";
import { dissmiss } from "../../../actions";

export const SelectedOptionForm = ({ regContent, verificationCbHandler }) => {
  const [initialState, setInitialState] = useState({
    otpCode: "",
    PhoneOrEmail: "",
    inActive: false
  });
  const content = regContent?.contentResource?.contents;
  const btnSendCode = ObjectUtils.extractLabelFromObject(content, "btnSendCode");
  const lblDetail1 = ObjectUtils.extractLabelFromObject(content, "lblDetail1");
  const lblDetail2 = ObjectUtils.extractLabelFromObject(content, "lblDetail2");
  const lblTitle = ObjectUtils.extractLabelFromObject(content, "lblTitle");
  const lblCancel = ObjectUtils.extractLabelFromObject(content, "btnCancel");
  const btnBack = ObjectUtils.extractLabelFromObject(content, "btnBack");

  // Email
  const lblNoEmailOnFileErrorMsg = ObjectUtils.extractLabelFromObject(content, "lblNoEmailOnFileErrorMsg");

  // Phone
  const lblNoPhoneNumberOnFileMsg = ObjectUtils.extractLabelFromObject(content, "lblNoPhoneNumberOnFileMsg");
  const lblDetailOptionlPhone = ObjectUtils.extractLabelFromObject(content, "lblDetailOptionlPhone");
  const lblOptionlPhone = ObjectUtils.extractLabelFromObject(content, "lblOptionlPhone");

  // QR Code
  const lblOptionlOTP = ObjectUtils.extractLabelFromObject(content, "lblOptionlOTP");
  const lblOption2OTP = ObjectUtils.extractLabelFromObject(content, "lblOption2OTP");
  const lblOption3OTP = ObjectUtils.extractLabelFromObject(content, "lblOption3OTP");
  const lblQRCode = ObjectUtils.extractLabelFromObject(content, "lblQRCode");
  const lblverificationCode = ObjectUtils.extractLabelFromObject(content, "lblverificationCode");
  const btnVerify = ObjectUtils.extractLabelFromObject(content, "btnVerify");
  const qrCodeLink = regContent?.qrCodeLink || "";

  const handleSubmit = (event) => {
    event.preventDefault();
    const options = {
      Code: initialState?.otpCode,
      PhoneOrEmail: initialState?.PhoneOrEmail,
      TypeID: regContent?.twoFATypeId,
      IsRegistration: true,
      resendCount: 0
    };

    return verificationCbHandler(options);
  };

  const handleCancel = () => {
    console.log("Cancel handler...");
  };

  const handleBack = () => {
    window.location.reload();
  };

  const setOptionalPhone = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setInitialState((prevState) => ({
        ...prevState,
        PhoneOrEmail: e.target.value
      }));
      if (e.target.value.length > 0 && e.target.value.length < 10) {
        setInitialState((prevState) => ({
          ...prevState,
          inActive: true
        }));
      } else {
        setInitialState((prevState) => ({
          ...prevState,
          inActive: false
        }));
        dissmiss();
      }
    }
  };

  const setOtpCode = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setInitialState((prevState) => ({
        ...prevState,
        otpCode: e.target.value
      }));
      if (e.target.value.length === 6) {
        setInitialState((prevState) => ({
          ...prevState,
          inActive: false
        }));
      } else {
        setInitialState((prevState) => ({
          ...prevState,
          inActive: true
        }));
      }
    }
  };

  return (
    <div className="conatiner" role="main" id="maincontent">
      <div className="login-holder">
        <Row className="h-100">
          <div className="col-12 login-image-holder"></div>
          <div className="logincontainer d-md-flex align-items-center align-content-center justify-content-between">
            <div className="holder login-form description panel d-none d-md-block">
              {/* <h1 className="login-heading d-sm-block d-md-none">{lblTitle.display}</h1> */}
              <span dangerouslySetInnerHTML={{ __html: lblDetail2.display }} />
            </div>
            <div className="form-group login-form mobile-login-form pb-5 pb-md-0">
              <h1 className="login-heading d-md-block">{lblTitle.display}</h1>
              <div className="login-form description panel d-md-none">
                <span dangerouslySetInnerHTML={{ __html: lblDetail2.display }} />
              </div>
              <div className="holder">
                <Form id="login-container" className="loginform" noValidate onSubmit={handleSubmit}>
                  <Row>
                    <Col className="col-12 paddingbottom">
                      {/* Check For QR CODE */}
                      {qrCodeLink && regContent?.twoFATypeId === 4 && (
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: lblDetail1.display
                            }}
                          />
                          <div>
                            <ol
                              style={{
                                fontSize: "14px"
                              }}
                            >
                              <li
                                dangerouslySetInnerHTML={{
                                  __html: lblOptionlOTP.display
                                }}
                              />
                              <li
                                dangerouslySetInnerHTML={{
                                  __html: lblOption2OTP.display
                                }}
                              />
                              <li className="nobull">
                                <img src={qrCodeLink} alt={lblQRCode.textToRead} />
                              </li>
                              <li
                                dangerouslySetInnerHTML={{
                                  __html: lblOption3OTP.display
                                }}
                              />
                            </ol>
                          </div>
                        </>
                      )}
                      {/* Check For EMAIL OTP */}
                      {regContent.isEmailExists && regContent?.twoFATypeId === 3 ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: lblDetail1.display
                          }}
                        />
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: lblNoEmailOnFileErrorMsg.display
                          }}
                        />
                      )}
                      {/* Check For SMS OTP */}
                      {regContent.isPhoneNoExists && regContent?.twoFATypeId === 2 ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: lblDetail1.display
                          }}
                        />
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: lblNoPhoneNumberOnFileMsg.display
                          }}
                        />
                      )}
                      {regContent.showOptionalPhone && regContent.isPhoneNoExists && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: lblDetailOptionlPhone.display
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    {/* Check For QR CODE */}
                    {qrCodeLink && (
                      <Col className="col-12 paddingbottom">
                        <label className="label-heading mb-2">{lblverificationCode.display}</label>
                        <Form.Control
                          type="text"
                          autoComplete="off"
                          placeholder={lblverificationCode.display}
                          className="form-control login-input-field"
                          value={initialState?.otpCode}
                          onChange={setOtpCode}
                          minLength={6}
                          maxLength={6}
                        />
                      </Col>
                    )}
                    {/* Check For SMS OTP */}
                    {regContent?.showOptionalPhone && (
                      <Col className="col-12 paddingbottom">
                        <label className="label-heading mb-2">{lblOptionlPhone.display}</label>
                        <Form.Control
                          type="text"
                          autoComplete="off"
                          placeholder={lblOptionlPhone.display}
                          className="form-control login-input-field"
                          value={initialState?.PhoneOrEmail}
                          minLength={10}
                          maxLength={10}
                          onChange={setOptionalPhone}
                        />
                      </Col>
                    )}
                    <Col>
                      <div className={`btns-forgot d-flex justify-content-between`}>
                        {regContent?.allowCancel && (
                          <ButtonComponent
                            btndata={[
                              {
                                variant: "outline-secondary",
                                display: lblCancel?.display,
                                onSubmit: handleCancel
                              }
                            ]}
                          />
                        )}

                        {!regContent?.isEmailExists ? (
                          <ButtonComponent
                            btndata={[
                              {
                                variant: "secondary",
                                display: btnBack?.display,
                                onSubmit: handleBack
                              }
                            ]}
                          />
                        ) : qrCodeLink ? (
                          <ButtonComponent
                            btndata={[
                              {
                                variant: "secondary",
                                display: btnVerify?.display,
                                disabled: initialState?.inActive,
                                type: "submit"
                              }
                            ]}
                          />
                        ) : (
                          <ButtonComponent
                            btndata={[
                              {
                                variant: "secondary",
                                display: btnSendCode?.display,
                                disabled: initialState?.inActive,
                                type: "submit"
                              }
                            ]}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default SelectedOptionForm;
