import { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTemporaryPasswordContent, setPageSetting, showError, showSuccess } from "../../actions";
import { changePassword, onChangePassword } from "../../actions/AuthActions";
import { PreloginComponent } from "../../components/Auth";
import { sessionChangePassoword } from "../../interfaces/formValidation";
import { RootState } from "../../reducers";
import { ObjectUtils } from "../../utils";
import { USER_TOKEN } from "../../utils/Storage";

export const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const textInputRefs = useRef<HTMLInputElement[]>([]);
  const changePasswordContent = useSelector((state: RootState) => state.common.temporaryPasswordContent);
  const { isSignedIn, authResponse } = useSelector((state: RootState) => state.auth);
  const { client } = USER_TOKEN?.get();

  const [sessionChangePassoword, setSessionChangePassoword] = useState<sessionChangePassoword>({
    password1: "",
    password2: "",
    formValidated: false
  });

  const onInputChange = (value: any) => {
    setSessionChangePassoword({
      ...sessionChangePassoword,
      ...value,
      formValidated: false
    });
  };

  const handleKeyDown = (event: FormEvent<EventTarget>): void => {
    handleSubmit(event);
  };

  const handleCancel = () => {
    if (authResponse?.passwordChangeRequired) {
      navigate("/logoff");
    } else {
      setPageSetting("WELCOME");
      navigate("/");
    }
  };

  const isValidation = () => {
    let isValid = false;
    const minPassWordLength = changePasswordContent ? changePasswordContent?.result?.minPassWordLength : 0;
    const maxPassWordLength = changePasswordContent ? changePasswordContent?.result?.maxPassWordLength : 0;
    const ComparePswdErrorMessage = ObjectUtils.extractLabelFromObject(changePasswordContent?.contentResource?.contents, "ComparePswdErrorMessage");
    const PasswordValidationMsg = ObjectUtils.extractLabelFromObject(changePasswordContent?.contentResource?.contents, "PasswordValidationMsg");
    if (!sessionChangePassoword.password1) {
      setSessionChangePassoword({
        ...sessionChangePassoword,
        formValidated: true
      });
      isValid = true;
      textInputRefs.current[0]?.focus();
    } else if (!sessionChangePassoword.password2) {
      setSessionChangePassoword({
        ...sessionChangePassoword,
        formValidated: true
      });
      isValid = true;
      textInputRefs.current[1]?.focus();
    } else if (sessionChangePassoword.password1 !== sessionChangePassoword.password2) {
      showError(ComparePswdErrorMessage ? ComparePswdErrorMessage.display : "error");
      isValid = true;
    } else if (sessionChangePassoword.password1.length < minPassWordLength || sessionChangePassoword.password2.length > maxPassWordLength) {
      showError(PasswordValidationMsg ? PasswordValidationMsg.display : "error");
      isValid = true;
    }
    return isValid;
  };

  //Need to update 2fa condition after 2FA imp.
  const handleSubmit = (event: FormEvent<EventTarget>): void => {
    event.preventDefault();
    if (!isValidation()) {
      const tfaDate: any = JSON.parse(localStorage.getItem("tfaDate") || "{}") || "";
      dispatch(
        changePassword(
          sessionChangePassoword.password1,
          tfaDate,
          (response) => {
            if (response?.statusCode === 200) {
              showSuccess(response?.result?.Message);
              const { needTwoFARegistration, needTwoFAValidation } = response;
              needTwoFARegistration || needTwoFAValidation ? onChangePassword(true, false) : onChangePassword(false, false);
              if (needTwoFARegistration || needTwoFAValidation) {
                navigate("/multi-factor");
              } else {
                navigate("/");
              }
            } else {
              showError(response?.errorMessage);
            }
          },
          (errorMessage) => {
            showError(errorMessage);
          }
        )
      );
    }
  };

  /**
   * This function will execute once this component
   * has been mounted.
   */
  useEffect(() => {
    //This gets app config data and save into redux store
    !changePasswordContent && dispatch(getTemporaryPasswordContent());
  }, [dispatch, changePasswordContent]);

  const contents = changePasswordContent?.contentResource?.contents;
  const configs = changePasswordContent?.result;
  const headerPage = ObjectUtils.extractLabelFromObject(contents, "lblChangePasswordTitle");
  const description = ObjectUtils.extractLabelFromObject(contents, "lblChangePasswordTitle");
  const description2 = ObjectUtils.extractLabelFromObject(contents, "lblChangePasswordData");
  const lblNewPassword = ObjectUtils.extractLabelFromObject(contents, "lblNewPassword");
  const lblReEnterNewPassword = ObjectUtils.extractLabelFromObject(contents, "lblReEnterNewPassword");
  const lblSubmit = ObjectUtils.extractLabelFromObject(contents, "lblSubmit");
  const lblCancel = ObjectUtils.extractLabelFromObject(contents, "btnCancel") || {
    textToRead: "Cancel",
    display: "Cancel"
  };
  const rfvReenterNewPasswordErrorMessage = ObjectUtils.extractLabelFromObject(contents, "rfvReenterNewPasswordErrorMessage");
  const rfvNewPasswordErrorMessage = ObjectUtils.extractLabelFromObject(contents, "rfvNewPasswordErrorMessage");

  const PasswordGuideLineMsg = ObjectUtils.extractLabelFromObject(contents, "PasswordGuideLineMsg");
  document.title = headerPage.display;

  const loginAuthObj = {
    description: {
      headingclassname: "login-heading d-md-block",
      level: "1",
      heading: headerPage.display,
      classname: "d-md-none",
      description: description.display,
      description2: description2.display
    },
    noHeadingDesciptions: {
      classname: "holder d-none d-md-block",
      description: description?.display,
      description2: description2?.display
    },
    formInput: {
      texttoread: headerPage.textToRead,
      handlesubmit: handleSubmit,
      classname: "loginform",
      validated: sessionChangePassoword.formValidated
    },
    textInput: [
      {
        type: "password",
        name: "password1",
        mode: "edit",
        classname: "form-control login-input-field",
        fgclass: "mb-3",
        display: lblNewPassword.display,
        labeltext: lblNewPassword.display,
        texttoread: lblNewPassword.textToRead,
        error: rfvNewPasswordErrorMessage.display,
        minLength: configs?.minPassWordLength,
        maxLength: configs?.maxPassWordLength,
        handlekeydown: handleKeyDown,
        handlechange: onInputChange,
        value: sessionChangePassoword.password1,
        refInput: (ref: HTMLInputElement) => (textInputRefs.current[0] = ref)
      },
      {
        type: "password",
        mode: "edit",
        name: "password2",
        classname: "form-control login-input-field",
        fgclass: "mb-3",
        display: lblReEnterNewPassword.display,
        labeltext: lblReEnterNewPassword.display,
        texttoread: lblReEnterNewPassword.textToRead,
        error: rfvReenterNewPasswordErrorMessage.display,
        minLength: configs?.minPassWordLength,
        maxLength: configs?.maxPassWordLength,
        value: sessionChangePassoword.password2,
        refInput: (ref: HTMLInputElement) => (textInputRefs.current[1] = ref),
        handlechange: onInputChange,
        handlekeydown: handleKeyDown
      }
    ],
    buttonInput: [
      lblCancel.display && {
        variant: "outline-secondary btn-cancel",
        display: lblCancel.display,
        onSubmit: handleCancel
      },
      lblSubmit.display && {
        type: "submit",
        variant: "secondary btn-submit float-end",
        display: lblSubmit.display
      }
    ],
    passwordGuideLineMsgInput: [
      {
        classname: "password-guideline",
        passwordGuideLineMsg: PasswordGuideLineMsg.display
      }
    ]
  };
  useEffect(() => {
    if (!isSignedIn) {
      console.log("--> REDIRECT ON CHANGE PW: ", client);
      navigate(`/${client}/Login`);
    }
  }, [client, isSignedIn, navigate]);

  return <PreloginComponent loginAuthObj={loginAuthObj}></PreloginComponent>;
};

export default ChangePassword;
