import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { disableTwoFA, getTwoFASettings } from "../../../actions/AuthActions";
import { ButtonComponent, DescriptionComponent } from "../../../components";
import { RootState } from "../../../reducers";
import { ObjectUtils } from "../../../utils";

export const TwoFASettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { tfaSettings } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(getTwoFASettings());
  }, [dispatch]);

  const disableTFA = async () => {
    await disableTwoFA((response: any) => {
      if (response) {
        navigate("/logoff");
      }
    });
  };

  const contents = tfaSettings?.contentResource?.contents;

  const isDisableAllowed = tfaSettings?.isDisableAllowed;
  const isTwoFAEnabled = tfaSettings?.isTwoFAEnabled;
  const pageTitle = ObjectUtils.extractLabelFromObject(contents, "lblTitle");
  const DisableTwoFA = ObjectUtils.extractLabelFromObject(contents, "DisableTwoFA");
  const EnableTwoFA = ObjectUtils.extractLabelFromObject(contents, "EnableTwoFA");
  const EnableTwoFAMsg = ObjectUtils.extractLabelFromObject(contents, "EnableTwoFAMsg");
  const DisableTwoFAMsg = ObjectUtils.extractLabelFromObject(contents, "DisableTwoFAMsg");

  const btnDisableTWoFA = [
    {
      display: DisableTwoFA.display,
      variant: "secondary btn btn-large btn-block col-xs-12 col-md-6 ",
      onSubmit: disableTFA
    }
  ];

  return (
    <>
      <div className="welcome-btns row">
        <DescriptionComponent level={1} headingclassname={"caption personal-info-caption"} heading={pageTitle.display} />
      </div>
      <div className="row">
        {isTwoFAEnabled && isDisableAllowed ? (
          <div className="mb-3 mb-lg-4">
            <p className="bold-text">
              <span>{EnableTwoFAMsg.display}</span>
            </p>
          </div>
        ) : (
          <div className="mb-3 mb-lg-4">
            <p className="bold-text">
              <span>{DisableTwoFAMsg.display}</span>
            </p>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-12 col-lg-12 text-center welcome-btn">
          {isTwoFAEnabled && isDisableAllowed && <ButtonComponent btndata={btnDisableTWoFA} />}
          {!isTwoFAEnabled && (
            <Link
              style={{
                outline: "none",
                boxShadow: "none"
              }}
              to="/multi-factor"
              aria-label={EnableTwoFA.textToRead}
              onClick={(e) => {
                e.currentTarget.blur();
              }}
              className="btn-large btn-block btn btn-secondary col-xs-12 col-md-6"
            >
              {EnableTwoFA.display}
            </Link>
          )}
          <div className="space-height-20" />
        </div>
      </div>
    </>
  );
};

export default TwoFASettings;
