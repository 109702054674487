import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { dissmiss } from "../../actions";
import { logoff } from "../../actions/AuthActions";
// import { UrlUtils } from "../../utils";
import { USER_TOKEN } from "../../utils/Storage";
import { useNavigate } from "react-router-dom";

export const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { client, isSignedIn } = USER_TOKEN.get();

  const handleSignOff = useCallback(async () => {
    dissmiss();
    dispatch(await logoff());
  }, [dispatch]);

  useEffect(() => {
    isSignedIn && handleSignOff();
    !isSignedIn && navigate(`/${client}/login`);
  }, [client, handleSignOff, isSignedIn, navigate]);

  return null;
};
