import { useDispatch, useSelector } from "react-redux";
import { EventUtils, ObjectUtils } from "../../utils";
import { RootState } from "../../reducers";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSummaryDependents, setPageSetting } from "../../actions";
import { SummaryPersonalModal } from "../../interfaces/formValidation";
import _ from "underscore";
import { ButtonComponent, DescriptionComponent } from "../../components";

export const SummaryDependents = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screens = useSelector((state: RootState) => state.common.screens);
  const summaryDependents = useSelector((state: RootState) => state.profile.summaryDependents);
  const [sessionSummaryDependents, setsessionSummaryDependents] = useState<SummaryPersonalModal>({
    showWarningModal: false,
    actionLink: ""
  });
  const getSummaryDependentsData = useCallback(async () => {
    dispatch(getSummaryDependents());
  }, [dispatch]);

  useEffect(() => {
    //This gets app config data and save into redux store
    getSummaryDependentsData();
  }, []);

  const handleEditAction = (link = "") => {
    link = sessionSummaryDependents?.actionLink ? sessionSummaryDependents?.actionLink : link;
    const menuItem = _.find(screens, (item) => item?.link === link);
    setPageSetting(menuItem ? menuItem.name : "WELCOME",true);
    navigate(`/${link}`);
  };

  const handleWarningModal = (link = "") => {
    if (sessionSummaryDependents?.showWarningModal) {
      setsessionSummaryDependents({
        showWarningModal: true,
        actionLink: link
      });
    } else {
      handleEditAction(link);
    }
  };

  const handleClose = () => {
    setsessionSummaryDependents({
      showWarningModal: false,
      actionLink: ""
    });
  };

  const renderDependentsDetail = (list: any, name: any, isNoCoverageVisible: any, dependentsCoverageHeader: any) => {
    if (list && list.length > 0) {
      const items: any = [];
      list?.map((item: any, index: any) => {
        items.push(
          <>
            <div key={`div-${name}-${item.benefitName}-${index}`} className="text-center valueboxes row">
              {dependentsCoverageHeader.benefit && (
                <div className="col">
                  <span className="visuallyhidden" dangerouslySetInnerHTML={{ __html: dependentsCoverageHeader.benefit }} />
                  {item.benefitName}
                </div>
              )}
              {dependentsCoverageHeader.coveredThisPlan && (
                <div className="col">
                  <span className="visuallyhidden" dangerouslySetInnerHTML={{ __html: dependentsCoverageHeader.coveredThisPlan.replace(/<br\s*\/?>/gi, " ") }} />
                  {item.isCoveredUnderThisPlan}
                </div>
              )}
              {dependentsCoverageHeader.coveredOtherPlan && (
                <div className="col">
                  <span className="visuallyhidden" dangerouslySetInnerHTML={{ __html: dependentsCoverageHeader.coveredOtherPlan.replace(/<br\s*\/?>/gi, " ") }} />
                  {item.isCoveredUnderOtherPlan}
                </div>
              )}
              {isNoCoverageVisible && (
                <div className="col">
                  <span className="visuallyhidden" dangerouslySetInnerHTML={{ __html: dependentsCoverageHeader.noCoverage }} />
                  {item.noCoverage}
                </div>
              )}
            </div>
          </>
        );
      });
      return <div className="col-10 col-sm-8 col"> {items}</div>;
    }
    return null;
  };
  console.log(handleClose);
  const pageTitle = summaryDependents?.tabName;
  const noDependentsMessage = summaryDependents?.noDependentsMessage;
  const displayDependentsInfo = summaryDependents?.displayDependentsInfo ? summaryDependents?.displayDependentsInfo : [];
  const isbtnEditDependentVisible = summaryDependents?.isbtnEditDependentVisible ? summaryDependents?.isbtnEditDependentVisible : false;
  const actionLink = summaryDependents?.actionlink;
  const dependentsCoverageHeader = summaryDependents?.dependentsCoverageHeader ? summaryDependents?.dependentsCoverageHeader : {};

  const tabIndexValue = 0;
  const isNoCoverageVisible = dependentsCoverageHeader.noCoverage && typeof dependentsCoverageHeader.noCoverage !== "undefined" && dependentsCoverageHeader.noCoverage !== null ? true : false;

  const contentFooter = useSelector((state: RootState) => state.common.contentFooter);
  const footerContent = contentFooter?.contentResource?.contents || {};
  const editIcon = ObjectUtils.extractLabelFromObject(footerContent, "EditIcon");
  const backButtonText = ObjectUtils.extractLabelFromObject(footerContent, "btnBack");
  document.title = pageTitle;
  const dependentsList: any = [];
  displayDependentsInfo?.map((dependent: any) => {
    const details = renderDependentsDetail(dependent.dependentDetail, dependent.dependentName, isNoCoverageVisible, dependentsCoverageHeader);
    dependentsList.push(
      <div key={`div-${dependent.dependentName}-${dependent.dob}-ID`} className="mb-4">
        <Card>
          <Card.Header>
            <DescriptionComponent headingclassname="float-left h2" level="2" heading={`${dependent.dependentName} / ${dependent.relationShip}`} />
            {isbtnEditDependentVisible && (
              <span
                className="float-right icon-action"
                tabIndex={tabIndexValue}
                role="button"
                aria-label={editIcon.textToRead + " " + dependent.dependentName}
                onKeyDown={(e) => EventUtils.keyPressed(e) && handleEditAction(actionLink)}
                onClick={() => {
                  handleWarningModal(actionLink);
                }}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </span>
            )}
          </Card.Header>
          <Card.Body>
            <div className="row mb-3">
              <div className="col">
                {dependent.dob} {dependent.smokerStatus && `, ${dependent.smokerStatus}`}
              </div>
            </div>
            {details && <div className="justify-content-end row">{details}</div>}
          </Card.Body>
        </Card>
      </div>
    );
  });

  const btnBack = [
    {
      display: backButtonText?.display,
      variant: "link",
      rightpos: true,
      displayclass: "m-0 mx-3",
      className: "float-left",
      icon: faChevronLeft,
      onSubmit: () => navigate("/Summary")
    }
  ];

  return (
    <div className="summarydependents clearfix">
      <div className={`d-flex titlewithicon mb-md-3`}>
        <ButtonComponent btndata={btnBack} />
      </div>
      <div>
        {!noDependentsMessage || dependentsList.length !== 0 ? (
          <>
            <div className="justify-content-end m-0 row">
              <div className="col-10 col-sm-8 col">
                <div className="justify-content-between text-center valueboxes valueboshead row">
                  {dependentsCoverageHeader?.benefit && <div className="col" dangerouslySetInnerHTML={{ __html: dependentsCoverageHeader?.benefit }} />}
                  {dependentsCoverageHeader?.coveredThisPlan && <div className="col" dangerouslySetInnerHTML={{ __html: dependentsCoverageHeader?.coveredThisPlan }}></div>}
                  {dependentsCoverageHeader?.coveredOtherPlan && <div className="col" dangerouslySetInnerHTML={{ __html: dependentsCoverageHeader?.coveredOtherPlan }}></div>}
                  {isNoCoverageVisible && <div className="col" dangerouslySetInnerHTML={{ __html: dependentsCoverageHeader?.noCoverage }}></div>}
                </div>
              </div>
            </div>
            {dependentsList}
          </>
        ) : (
          noDependentsMessage
        )}
      </div>
      {/* <WarningMessageModal showWarningModal={this.state.showWarningModal} clickAction={this.handleEditAction} handleClose={this.handleClose} /> */}
    </div>
  );
};

export default SummaryDependents;
