import { faChevronLeft, faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { downloadUploadedFile, getFileUploadInfoContent, getUploadedFilesInfoContent, showError, showSuccess } from "../../actions";
import { uploadEmployeeDocuments } from "../../actions/AuthActions";
import { UploadFileIcon } from "../../assets/icons";
import { ButtonComponent, ModalComponent, TableComponent } from "../../components";
import { FileUploadConfigConstants } from "../../constants";
import { RootState } from "../../reducers";
import { ObjectUtils } from "../../utils";
export const UploadFile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const textInputRefs = useRef<HTMLInputElement>();
  const { uploadedFilesInfoContent, fileUploadInfoContent }: any = useSelector((state: RootState) => state.common);
  const { pageSetting } = useSelector((state: RootState) => state.event);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [selectedFileCategoryId, setSelectedFileCategoryId] = useState<any>(0);
  const [errorMsg, setErrorMsg] = useState<any>("");
  const [filteredExtentions, setFilteredExtentions] = useState<any>([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [isModalVisible, setIsModalVisible] = useState<any>(false);

  const handleModalVisibility = () => {
    setIsModalVisible(true);
  };

  const makeAcceptanceList = useCallback(
    (selectedCategoryID: any) => {
      const extentions = fileUploadInfoContent?.fileCategoryTypesList.filter((cat: any) => cat.categoryID === selectedCategoryID).map((cat: any) => "." + cat.fileExtension.toLowerCase());
      setFilteredExtentions(extentions);
    },
    [fileUploadInfoContent?.fileCategoryTypesList]
  );

  useEffect(() => {
    //This gets app config data and save into redux store
    (!uploadedFilesInfoContent || uploadedFilesInfoContent === null) &&
      dispatch(
        getUploadedFilesInfoContent(
          (response) => {
            console.log(response);
            // response.statusCode === 200 ? setUploadedFileInfoContent(response) : showError(response.errorMessage);
          },
          () => {
            console.log("error");
          }
        )
      );
  }, [dispatch, uploadedFilesInfoContent]);

  /**
   * This function will execute once this component
   * has been mounted.
   */
  useEffect(() => {
    //This gets app config data and save into redux store
      dispatch(
        getFileUploadInfoContent(
          (response) => {
            if (response.statusCode === 200) {
              console.log(response);
              // setFileUploadInfoContent(response);
              const categoryID = response?.fileCategoryList[0]?.categoryID;
              setSelectedFileCategoryId(categoryID);
            } else showError(response.errorMessage);
          },
          () => {
            console.log("error");
          }
        )
      );
  }, [dispatch]);

  useEffect(() => {
    makeAcceptanceList(parseInt(selectedFileCategoryId));
  }, [makeAcceptanceList, selectedFileCategoryId]);

  const downloadUploadFile = (fileId: any, fileName: string) => {
    dispatch(
      downloadUploadedFile(
        fileId,
        fileName,
        (response) => {
          ObjectUtils.downloadFile(response, fileName);
        },
        (errorMsg) => {
          showError(errorMsg);
        }
      )
    );
  };

  const onSubmitClickHandler = () => {
    if (!selectedFile) {
      setErrorMsg(noFileSelectedErrorMessage.display);
    } else {
      uploadFile();
    }
  };

  const uploadFile = () => {
    setIsModalVisible(true);
    const formData = new FormData();
    formData.append("FileCategoryID", selectedFileCategoryId);
    formData.append("FormFileContent", selectedFile);
    dispatch(
      uploadEmployeeDocuments(
        formData,
        (response) => {
          if (response.statusCode === 200) {
            setIsModalVisible(false);
            setSelectedFile(null);
            dispatch({
              type: FileUploadConfigConstants.UPLOADED_FILES_INFO,
              payload: undefined
            });
            //setUploadedFileInfoContent(undefined);
            showSuccess(response?.result?.Message);
          } else {
            showError(response?.errorMessage);
          }
        },
        (errorMessage) => {
          showError(errorMessage);
        }
      )
    );
  };

  const handleBack = () => {
    if (pageSetting.link.toLowerCase().includes("fileupload")) navigate("/");
    else if (pageSetting.link) navigate(`/${pageSetting.link}`);
    else navigate("/EnrollmentConfirmation");
  };

  const contents = uploadedFilesInfoContent?.contentResource?.contents;
  const contentStrings = fileUploadInfoContent?.contentResource?.contents;
  const uploadedFileListTitle = ObjectUtils.extractLabelFromObject(contents, "UploadedFilesTitle");
  const fileNameHeading = ObjectUtils.extractLabelFromObject(contents, "LableFileName");
  const categoryNameHeading = ObjectUtils.extractLabelFromObject(contents, "CategoryTitle");
  const statusHeading = ObjectUtils.extractLabelFromObject(contents, "LabelStatus");
  const dateHeading = ObjectUtils.extractLabelFromObject(contents, "LabelDate");
  const downloadFileHeading = ObjectUtils.extractLabelFromObject(contents, "LabelDownloadButton");
  const uploadFileButtonLabel = ObjectUtils.extractLabelFromObject(contents, "LabelUploadLinkButton");
  const selectCategoryLabel = ObjectUtils.extractLabelFromObject(contentStrings, "LabelSelectCategory");
  const LabelFileUploadTitle = ObjectUtils.extractLabelFromObject(contentStrings, "LabelFileUploadTitle");
  const noFileSelectedErrorMessage = ObjectUtils.extractLabelFromObject(contentStrings, "LabelNoFileSelected");
  const screenTitle = ObjectUtils.extractLabelFromObject(contentStrings, "LabelUploadedPageTitle");
  const selectFileLabel = ObjectUtils.extractLabelFromObject(contentStrings, "LabelSelectFile");
  const fileSizeLimitError = ObjectUtils.extractLabelFromObject(contentStrings, "FileSizeLimitMessage");
  const browseFileLabel = ObjectUtils.extractLabelFromObject(contentStrings, "LabelBrowseFiles");
  const fileUploadPageTitleLabel = ObjectUtils.extractLabelFromObject(contentStrings, "LabelFileUploadPageTitle");
  const validationNotSupportMessage = ObjectUtils.extractLabelFromObject(contentStrings, "ValidationNotSupportMessage");
  const fileUploadPageFooterMessage = ObjectUtils.extractLabelFromObject(contentStrings, "LabelFileUploadPageFooterMessage");
  const okButtonLabel = ObjectUtils.extractLabelFromObject(contentStrings, "LabelUploadButton");
  const cancelButtonLabel = ObjectUtils.extractLabelFromObject(contentStrings, "LabelCancelButton");
  const uploadedFileInfoList = uploadedFilesInfoContent?.fileInfoList;
  const uploadedFileLength = uploadedFilesInfoContent?.fileInfoList.length;
  const contentFooter = useSelector((state: RootState) => state?.common?.contentFooter?.contentResource?.contents);
  const backButton = ObjectUtils.extractLabelFromObject(contentFooter, "btnBack");
  const uploadedPageFooterMessage = ObjectUtils.extractLabelFromObject(contentStrings, "LabelUploadedPageFooterMessage");

  const fileCategoryList: any = [];
  fileUploadInfoContent?.fileCategoryList?.map((item: any) => {
    fileCategoryList.push({
      text: item?.categoryName,
      value: item?.categoryID
    });
  });

  const changeTheCatgoryName = (callback: any) => {
    setSelectedFileCategoryId(parseInt(callback?.fileCategory));
    makeAcceptanceList(parseInt(callback?.fileCategory));
    setSelectedFile(null);
    setErrorMsg("");
  };

  const onSelectedFileChangeHandler = (event: any) => {
    setSelectedFile(null);
    if (event.target.files[0].size <= parseInt(fileUploadInfoContent?.fileSizeLimit) * 1e6) {
      const isValidExt = filteredExtentions.filter((ext: any) => `.${event.target.files[0].type.split("/")[1]}` === ext).length > 0;
      if (isValidExt) {
        setSelectedFile(event.target.files[0]);
        setSelectedFileName(event.target.files[0].name);
        setErrorMsg("");
      } else {
        setErrorMsg(validationNotSupportMessage.display);
      }
    } else {
      setErrorMsg(fileSizeLimitError.display);
    }
  };

  const handlePopUpClose = () => {
    setIsModalVisible(false);
    setErrorMsg("");
    setSelectedFile(null);
    setSelectedFileName(browseFileLabel?.display);
  };

  useEffect(() => {
    setSelectedFileName(browseFileLabel.display);
  }, [browseFileLabel]);

  const buttonInput = [
    {
      //type: "submit",
      display: (
        <>
          <UploadFileIcon />
          {uploadFileButtonLabel.display}
        </>
      ),
      onSubmit: handleModalVisibility,
      variant: "outline-secondary btn-fileupload btn-popup"
    }
  ];

  const btnBack = [
    {
      //type: "submit",
      display: backButton.display,
      variant: "link",
      rightpos: true,
      displayclass: "m-0 mx-3",
      className: "float-left",
      icon: faChevronLeft,
      onSubmit: handleBack
    }
  ];
  const fileUploadButtons = [
    {
      type: "submit",
      variant: "outline-secondary btn-cancel",
      display: cancelButtonLabel.display,
      onSubmit: handlePopUpClose
    },
    {
      type: "submit",
      variant: "outline-secondary btn-fileupload",
      display: okButtonLabel.display,
      onSubmit: onSubmitClickHandler
    }
  ];

  const headerData: any = [
    { headername: fileNameHeading.display },
    { headername: categoryNameHeading.display },
    { headername: dateHeading.display },
    { headername: statusHeading.display },
    { headername: downloadFileHeading.display }
  ];

  const gridRowBodyObject: any = [];
  uploadedFileInfoList?.map((item: any) => {
    const gridRowData: any = [
      {
        type: "text",
        display: item?.fileName,
        datatitle: fileNameHeading.display
      },
      {
        type: "text",
        display: item?.categoryName,
        datatitle: categoryNameHeading.display
      },
      {
        type: "text",
        display: item?.uploadedDate,
        datatitle: dateHeading.display
      },
      {
        type: "text",
        display: item?.status,
        datatitle: statusHeading.display
      },
      {
        type: "submit",
        variant: " btn btn-secondary btn-uploadfile",
        iconclassname: "ms-12 ",
        padlevel: "pb-3",
        icon: faCloudDownloadAlt,
        datatitle: downloadFileHeading.display,
        onSubmit: () => downloadUploadFile(item?.fileID, item?.fileName)
      }
    ];
    gridRowBodyObject.push(gridRowData);
  });

  const tableObject = {
    className: "tableupload w-100",
    header: {
      headerData: headerData
    },
    body: {
      gridRowData: gridRowBodyObject
    },
    footer: {
      messageText: uploadedPageFooterMessage.display,
      messageType: "primary  mt-4"
    }
  };

  // Modal Object Data start
  const gridRowData: any = [
    [
      {
        type: "select",
        name: "fileCategory",
        colclassname: " ",
        texttoread: selectCategoryLabel.texttoread,
        display: selectCategoryLabel.display,
        mode: "edit",
        options: fileCategoryList,
        handlechange: changeTheCatgoryName,
        fgclass: "fieldgroup clearfix mb-3 mt-3",
        refInput: textInputRefs
      }
    ],
    [
      {
        type: "file",
        name: "fileupload",
        colclassname: " ",
        texttoread: selectFileLabel.textToRead,
        display: selectFileLabel.display,
        filterextentions: filteredExtentions,
        mode: "edit",
        browsefilelabel: selectedFileName,
        ctlclass: "invisible position-absolute",
        errormessage: errorMsg,
        handlechange: onSelectedFileChangeHandler,
        fgclass: "fieldgroup clearfix",
        gridrow: "uploadcustome",
        propmessageclass: "errorMessage fileerror"
      }
    ]
  ];

  const modalObject = {
    showModal: isModalVisible,
    clickHandler: handlePopUpClose,
    dialogClass: "ml-addnewbeneficiary",
    head: {
      modalTitle: fileUploadPageTitleLabel.display
    },
    body: {
      gridRowData: gridRowData,
      messageText: fileUploadPageFooterMessage.display,
      messageType: "primary  mt-4",
      confirmBoxData: {
        messagetitle: LabelFileUploadTitle.display
      }
    },
    footer: {
      footerData: fileUploadButtons
    },
    errorList: [errorMsg] || null
  };
  // Modal Object Data End
  document.title = screenTitle.display;
  return (
    <>
      <div className={`uploadcontent`}>
        <div className="row justify-content-between align-items-center align-items-md-end mb-3 mb-md-5">
          <div className={`col-xs-12 col-sm-auto`}>
            <div className={`ml-titledescription`}>
              <div className={`d-flex titlewithicon`}>
                <ButtonComponent btndata={btnBack} />
                <div></div>
              </div>
            </div>
          </div>
          <div className={`col-xs-12 col-sm-auto`}>{<ButtonComponent btndata={buttonInput} />}</div>
        </div>

        <div className={`row`}>
          <div className={` col-12`}>
            <div className={` uploadstatus`}>
              <p>
                {uploadedFileListTitle.display} <span>({uploadedFileLength})</span>
              </p>
            </div>
          </div>
        </div>
        <div className={`row `}>
          <div className={`col-12`}>
            <TableComponent tableObject={tableObject}></TableComponent>
          </div>
          {<ModalComponent modalObject={modalObject} />}
        </div>
      </div>
    </>
  );
};

export default UploadFile;
