import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfileContent, showSuccess, updatePersonalInfo } from "../../actions";
import { AlertComponent, CardComponent } from "../../components";
import { emailErrorMessage, infoErrorMessage, phoneErrorMessage } from "../../interfaces/formValidation";
import { RootState } from "../../reducers";
import { ObjectUtils } from "../../utils";

export const ReviewPersonalInfo = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [infoErrorMessage, setinfoErrorMessage] = useState<infoErrorMessage>({
    firstNameError: "",
    lastNameError: "",
    initialError: "",
    address1Error: "",
    address2Error: "",
    cityError: "",
    postalCodeError: "",
    isValid: true
  });
  const [phoneErrorMessage, setphoneErrorMessage] = useState<phoneErrorMessage>({
    workAreaCodeError: "",
    workPhoneNumberError: "",
    workextensionError: "",
    homeAreaCodeError: "",
    homePhoneNumberError: "",
    isValid: true
  });

  const [emailErrorMessage, setemailErrorMessage] = useState<emailErrorMessage>({
    workemailError: "",
    personalemailError: ""
  });

  const [emailMode, setemailMode] = useState<string>("view");
  const [infoMode, setinfoMode] = useState<string>("view");
  const [phoneMode, setphoneMode] = useState<string>("view");
  const [errorList, setErrorList] = useState<string[]>([]);

  const [smokerDeclarationChanged, setsmokerDeclarationChanged] = useState<boolean>(false);
  const { contentFooter } = useSelector((state: RootState) => state.common);
  const profileData = useSelector((state: RootState) => state.profile.personalInfo);
  const [sessioncurrentUser, setsessioncurrentUser] = useState<any>([]);

  useEffect(() => {
    profileData && setsessioncurrentUser(profileData?.result || []);
  }, [profileData]);

  const getProfileData = useCallback(
    async (isReview: boolean) => {
      dispatch(await getProfileContent(isReview));
    },
    [dispatch]
  );

  useEffect(() => {
    setErrorList([]);
    // if (!profileData) {
    // console.log("profileData: ", profileData);
    if (localStorage.getItem("isReview") || localStorage.getItem("lifeEvent")) {
      getProfileData(false);
    } else {
      getProfileData(true);
    }
    // }
  }, [getProfileData]);

  const changePreferredEmailStatus = async (value: any) => {
    setemailErrorMessage({ workemailError: "", personalemailError: "" });

    const newvalue: any = {};
    newvalue.selectedEmail = value?.selectedpersonalEmail ? value?.selectedpersonalEmail : value?.selectedWorkEmail;

    const currentUser: any = { ...sessioncurrentUser, ...newvalue };
    setsessioncurrentUser(currentUser);

    dispatch(await updatePersonalInfo(currentUser));
  };

  const onInputChange = (value: any) => {
    if (infoMode == "edit") isValidInfo(value);
    setsessioncurrentUser({
      ...sessioncurrentUser,
      ...value
    });
  };

  const onInputChangeAddress = (value: any) => {
    isValidInfo(value);
    setsessioncurrentUser({
      ...sessioncurrentUser,
      address: {
        ...sessioncurrentUser.address,
        ...value
      }
    });
  };

  const changeSmokerStatus = async (callback: any) => {
    const value: any = Object.values(callback)[0];
    setsmokerDeclarationChanged(true);
    const currentUser: any = { ...sessioncurrentUser, smokerStatus: parseInt(value) };
    setsessioncurrentUser(currentUser);
    dispatch(await updatePersonalInfo(currentUser));
  };

  const isValidEmailInfo = () => {
    let isValid = true;
    setemailErrorMessage({ workemailError: "", personalemailError: "" });
    if (ObjectUtils.isTest(sessioncurrentUser?.workEmail, profileData?.validationWorkEmail)) {
      setemailErrorMessage({
        ...emailErrorMessage,
        workemailError: regWorkEmailErrorMessage
      });
      isValid = false;
    }
    if (ObjectUtils.isTest(sessioncurrentUser?.personalEmail, profileData?.validationPersonalEmail)) {
      setemailErrorMessage({
        ...emailErrorMessage,
        personalemailError: regPersonalEmailErrorMessage
      });
      isValid = false;
    }
    return isValid;
  };

  // useEffect(() => {
  //   //isValidPhoneInfo();
  // }, [sessioncurrentUser]);

  const isValidInfo = (value: any) => {
    const { validationforFirstName, validationforLastName, validationforInitial, validationForAddress, validationPostalCodeUS, validationPostalCodeCanada } = profileData || {};
    const { countryCode } = sessioncurrentUser?.address || {};
    const validationPostalCode = countryCode == "CA" ? validationPostalCodeCanada : countryCode == "US" ? validationPostalCodeUS : "";
    const regPostalCodeErrorMessage = countryCode == "CA" ? regPostalCodeCanadaErrorMessage : countryCode == "US" ? regPostalCodeUSErrorMessage : "";

    if (value.firstName !== undefined) {
      if (ObjectUtils.isTest(value?.firstName, validationforFirstName)) {
        infoErrorMessage.firstNameError = regFirstNameErrorMessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.firstNameError = "";
        infoErrorMessage.isValid = true;
      }
    }

    if (value.lastName !== undefined) {
      if (ObjectUtils.isTest(value?.lastName, validationforLastName)) {
        infoErrorMessage.lastNameError = regLastNameErrorMessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.lastNameError = "";
        infoErrorMessage.isValid = true;
      }
    }

    if (value.initial !== undefined) {
      if (ObjectUtils.isTest(value?.initial, validationforInitial)) {
        infoErrorMessage.initialError = regMiddleInitialErrorMessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.initialError = "";
        infoErrorMessage.isValid = true;
      }
    }

    if (value.address1 !== undefined) {
      if (ObjectUtils.isTest(value?.address1, validationForAddress)) {
        infoErrorMessage.address1Error = regAddress1errormessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.address1Error = "";
        infoErrorMessage.isValid = true;
      }
    }

    if (value.address2 !== undefined) {
      if (ObjectUtils.isTest(value?.address2, validationForAddress)) {
        infoErrorMessage.address2Error = regAddress2errormessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.address2Error = "";
        infoErrorMessage.isValid = true;
      }
    }

    if (value.city !== undefined) {
      if (ObjectUtils.isTest(value?.city, validationForAddress)) {
        infoErrorMessage.cityError = regCityerrormessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.cityError = "";
        infoErrorMessage.isValid = true;
      }
    }

    if (value.postalCode !== undefined) {
      if (ObjectUtils.isTest(value?.postalCode, validationPostalCode)) {
        infoErrorMessage.postalCodeError = regPostalCodeErrorMessage;
        infoErrorMessage.isValid = false;
      } else {
        infoErrorMessage.postalCodeError = "";
        infoErrorMessage.isValid = true;
      }
    }
    setinfoErrorMessage(infoErrorMessage);
  };

  const isValidHomePhoneInfo = (value: any) => {
    if (value.areaCode !== undefined) {
      if (ObjectUtils.isTest(value?.areaCode, profileData?.validationHomePhoneAreaCode)) {
        phoneErrorMessage.homeAreaCodeError = regHomePhoneAreaErrorMessage;
        phoneErrorMessage.isValid = false;
      } else {
        phoneErrorMessage.homeAreaCodeError = "";
        phoneErrorMessage.isValid = true;
      }
    }

    if (value.phoneNumber !== undefined) {
      if (ObjectUtils.isTest(value?.phoneNumber, profileData?.validationHomePhoneNumber)) {
        phoneErrorMessage.homePhoneNumberError = regHomePhoneErrorMessage;
        phoneErrorMessage.isValid = false;
      } else {
        phoneErrorMessage.homePhoneNumberError = "";
        phoneErrorMessage.isValid = true;
      }
    }
    setphoneErrorMessage(phoneErrorMessage);
  };

  const isValidWorkPhoneInfo = (value: any) => {
    const { validationWorkPhoneAreaCode, validationWorkPhoneNumber, validationWorkPhoneExtension } = profileData || {};
    if (value.areaCode !== undefined) {
      if (ObjectUtils.isTest(value?.areaCode, validationWorkPhoneAreaCode)) {
        phoneErrorMessage.workAreaCodeError = regWorkPhoneAreaErrorMessage;
        phoneErrorMessage.isValid = false;
      } else {
        phoneErrorMessage.workAreaCodeError = "";
        phoneErrorMessage.isValid = true;
      }
    }

    if (value.phoneNumber !== undefined) {
      if (ObjectUtils.isTest(value?.phoneNumber, validationWorkPhoneNumber)) {
        phoneErrorMessage.workPhoneNumberError = regWorkPhoneErrorMessage;
        phoneErrorMessage.isValid = false;
      } else {
        phoneErrorMessage.workPhoneNumberError = "";
        phoneErrorMessage.isValid = true;
      }
    }
    if (value.extension !== undefined) {
      if (ObjectUtils.isTest(value?.extension, validationWorkPhoneExtension)) {
        phoneErrorMessage.workextensionError = regExtensionErrorMessage;
        phoneErrorMessage.isValid = false;
      } else {
        phoneErrorMessage.workextensionError = "";
        phoneErrorMessage.isValid = true;
      }
    }
    setphoneErrorMessage(phoneErrorMessage);
  };

  const changeHomePhone = (value: any) => {
    isValidHomePhoneInfo(value);
    setsessioncurrentUser({
      ...sessioncurrentUser,
      homePhone: {
        ...sessioncurrentUser.homePhone,
        ...value
      }
    });
  };

  const changeWorkPhone = (value: any) => {
    isValidWorkPhoneInfo(value);
    setsessioncurrentUser({
      ...sessioncurrentUser,
      workPhone: {
        ...sessioncurrentUser.workPhone,
        ...value
      }
    });
  };

  const handleSaveClick = (cardName: string) => {
    if (cardName === "email") {
      const isValid = isValidEmailInfo();
      if (isValid) handleSubmit(cardName);
    } else handleSubmit(cardName);
  };

  const handleSubmit = async (cardName: string) => {
    dispatch(
      await updatePersonalInfo(
        sessioncurrentUser,
        (response) => {
          setErrorList([]);
          if (cardName === "info") reverseInfoMode();
          else if (cardName === "phone") reversePhoneMode();
          else if (cardName === "email") reverseEmailMode();
          response?.result?.Message && showSuccess(response?.result?.Message);
        },
        (errorMessage) => {
          setErrorList(errorMessage);
        }
      )
    );
  };

  const cancel = (value: string) => {
    if (value == "info") setinfoMode(infoMode === "edit" ? "view" : "edit");
    else if (value == "phone") setphoneMode(phoneMode === "edit" ? "view" : "edit");
    else if (value == "email") setemailMode(emailMode === "edit" ? "view" : "edit");

    setsessioncurrentUser({ ...profileData?.result });
    setErrorList([]);
    setinfoErrorMessage({
      firstNameError: "",
      lastNameError: "",
      initialError: "",
      address1Error: "",
      address2Error: "",
      cityError: "",
      postalCodeError: "",
      isValid: true
    });
    setphoneErrorMessage({
      workAreaCodeError: "",
      workPhoneNumberError: "",
      workextensionError: "",
      homeAreaCodeError: "",
      homePhoneNumberError: "",
      isValid: true
    });
    setemailErrorMessage({
      workemailError: "",
      personalemailError: ""
    });
  };

  const reverseEmailMode = () => {
    if (infoMode == "edit") cancel("info");
    if (phoneMode == "edit") cancel("phone");
    setemailMode(emailMode == "edit" ? "view" : "edit");
    setemailErrorMessage({
      workemailError: "",
      personalemailError: ""
    });
    getProfileData(false);
  };

  const reversePhoneMode = () => {
    if (infoMode == "edit") cancel("info");
    if (emailMode == "edit") cancel("email");
    setphoneMode(phoneMode == "edit" ? "view" : "edit");
    setphoneErrorMessage({
      workAreaCodeError: "",
      workPhoneNumberError: "",
      workextensionError: "",
      homeAreaCodeError: "",
      homePhoneNumberError: "",
      isValid: true
    });
    getProfileData(false);
  };

  const reverseInfoMode = () => {
    if (phoneMode == "edit") cancel("phone");
    if (emailMode == "edit") cancel("email");
    setinfoMode(infoMode === "edit" ? "view" : "edit");
    getProfileData(false);
  };

  const contents1 = profileData?.contentResource?.contents || [];
  const contents2 = profileData?.contentResource?.controlResource?.PersonalInfo || [];
  const contents: any = [...contents1, ...contents2];
  const { isAddressEditable, isNameEditable, isMaritalStatusUsed, isHomeEmailEditable, isWorkEmailEditable, isHomePhoneEditable, isWorkPhoneEditable, changeNote, showWorkPhone } = profileData || {};
  const showInfoEditable = isAddressEditable || isNameEditable || isMaritalStatusUsed;
  const showEmailEditable = isHomeEmailEditable || isWorkEmailEditable;
  const showPhoneEditable = isHomePhoneEditable || isWorkPhoneEditable;
  const lblBirthDate = ObjectUtils.extractLabelFromObject(contents, "lblBirthDate");
  const lblGender = ObjectUtils.extractLabelFromObject(contents, "lblGender");
  const lblMaritalStatus = ObjectUtils.extractLabelFromObject(contents, "lblMaritalStatus");
  const lblLanguage = ObjectUtils.extractLabelFromObject(contents, "lblLanguage");
  // const regFirstNameErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regFirstNameErrorMessage");

  const pnlPhoneGroupingText = ObjectUtils.extractLabelFromObject(contents, "pnlPhoneGroupingText");
  const pnlEmailGroupingText = ObjectUtils.extractLabelFromObject(contents, "pnlEmailGroupingText");

  const footerContent = contentFooter?.contentResource?.contents || {};
  const btnCancel = ObjectUtils.extractLabelFromObject(footerContent, "btnCancel");
  const btnSave = ObjectUtils.extractLabelFromObject(footerContent, "btnSave");
  const editIcon = ObjectUtils.extractLabelFromObject(footerContent, "EditIcon");
  const lblWorkEmail = ObjectUtils.extractLabelFromObject(contents, "lblWorkEmail");
  const lblPersonalEmail = ObjectUtils.extractLabelFromObject(contents, "lblPersonalEmail");
  const regAddress1errormessage = ObjectUtils.extractLabelFromObject(contents, "regAddress1ErrorMessage").display;
  const regAddress2errormessage = ObjectUtils.extractLabelFromObject(contents, "regAddress2ErrorMessage").display;
  const regCityerrormessage = ObjectUtils.extractLabelFromObject(contents, "regCityErrorMessage").display;
  const regPostalCodeCanadaErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regPostalCodeCanadaErrorMessage").display;
  const regPostalCodeUSErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regPostalCodeUSErrorMessage").display;
  const lblWorkPhone = ObjectUtils.extractLabelFromObject(contents, "lblWorkPhone");
  const lblHomePhone = ObjectUtils.extractLabelFromObject(contents, "lblHomePhone");
  const lblEditHomePhone = ObjectUtils.extractLabelFromObject(contents, "lblEditHomePhone");
  const lblEditWorkPhone = ObjectUtils.extractLabelFromObject(contents, "lblEditWorkPhone");
  const lblEditWorkEmail = ObjectUtils.extractLabelFromObject(contents, "lblEditWorkEmail");
  const lblEditPersonalEmail = ObjectUtils.extractLabelFromObject(contents, "lblEditPersonalEmail");
  const lblProfileWorkEmailPlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfileWorkEmailPlaceHolder");
  const lblProfilePersonalEmailPlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfilePersonalEmailPlaceHolder");
  const regHomePhoneErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regHomePhoneErrorMessage").display;
  const regWorkPhoneErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regWorkPhoneErrorMessage").display;
  const regWorkPhoneAreaErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regWorkPhoneAreaErrorMessage").display;
  const regHomePhoneAreaErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regHomePhoneAreaErrorMessage").display;
  const regExtensionErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regExtensionErrorMessage").display;
  const regWorkEmailErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regWorkEmailErrorMessage").display;
  const regPersonalEmailErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regPersonalEmailErrorMessage").display;

  const regLastNameErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regLastNameErrorMessage").display;
  const regFirstNameErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regFirstNameErrorMessage").display;
  const regMiddleInitialErrorMessage = ObjectUtils.extractLabelFromObject(contents, "regMiddleInitialErrorMessage").display;
  const lblPreferredEmail = ObjectUtils.extractLabelFromObject(contents, "lblPreferredEmail");
  const lblEditAddress = ObjectUtils.extractLabelFromObject(contents, "lblEditAddress");
  const lblProfileAddress1PlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfileAddress1PlaceHolder");
  const lblProfileAddress2PalceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfileAddress2PalceHolder");
  const lblProfileCityPlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfileCityPlaceHolder");
  const lblProfilePostalCodePlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfilePostalCodePlaceHolder");
  const lblProfileHomePhoneAreaCodePlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfileHomePhoneAreaCodePlaceHolder");
  const lblProfileHomePhonePhoneNumberPlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfileHomePhonePhoneNumberPlaceHolder");
  const lblProfileInitialPlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfileInitialPlaceHolder");
  const lblEditInitial = ObjectUtils.extractLabelFromObject(contents, "lblEditInitial");
  const lblEditLastName = ObjectUtils.extractLabelFromObject(contents, "lblEditLastName");
  const lblEditFirstName = ObjectUtils.extractLabelFromObject(contents, "lblEditFirstName");
  const lblProfileFirstNamePlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfileFirstNamePlaceHolder");
  const lblProfileLastNamePlaceHolder = ObjectUtils.extractLabelFromObject(contents, "lblProfileLastNamePlaceHolder");
  const lblSmoker = ObjectUtils.extractLabelFromObject(contents, "lblSmoker").display;
  const lblNonSmoker = ObjectUtils.extractLabelFromObject(contents, "lblNonSmoker").display;
  const smokerStatusTitle = ObjectUtils.extractLabelFromObject(contents, "pnlSmokerGroupingText");
  const nonSmokerMessage = ObjectUtils.extractLabelFromObject(contents, "lblIsNonSmoker");
  const smokerMessage = ObjectUtils.extractLabelFromObject(contents, "lblIsSmoker");

  let smokerStatusMessage: any = "";
  if (["0", 0].indexOf(sessioncurrentUser?.smokerStatus) > -1) smokerStatusMessage = nonSmokerMessage;
  if (["1", 1].indexOf(sessioncurrentUser?.smokerStatus) > -1) smokerStatusMessage = smokerMessage;

  const address = sessioncurrentUser?.address || {};

  const returnAddressText = () => {
    let address = "";
    if (profileData?.address1Text) address += `${profileData?.address1Text}<br/>`;
    if (profileData?.address2Text) address += `${profileData?.address2Text}`;
    return address;
  };
  const addressText = returnAddressText();
  const infoObject: any = {
    head: {
      gridtitle: profileData?.employeeNameText,
      texttoread: editIcon.textToRead,
      showediticon: showInfoEditable,
      editbtndata: {
        className: "link icon-action edit-icon float-right",
        ariaLabel: editIcon.textToRead + " " + profileData?.employeeNameText,
        onSubmit: reverseInfoMode
      }
    },
    body: {
      gridRowData: [
        isNameEditable &&
          infoMode === "edit" && [
            {
              type: "text",
              name: "firstName",
              mode: !isNameEditable ? "edit" : "view",
              display: lblEditFirstName.display,
              texttoread: lblProfileFirstNamePlaceHolder.display,
              value: sessioncurrentUser?.firstName,
              maxLength: 50,
              handlechange: onInputChange,
              errormessage: infoErrorMessage?.firstNameError,
              colclassname: "col-sm-12 col-md-4"
            },
            {
              type: "text",
              name: "lastName",
              mode: isNameEditable ? "edit" : "view",
              display: lblEditLastName.display,
              texttoread: lblProfileLastNamePlaceHolder.display,
              value: sessioncurrentUser?.lastName,
              maxLength: 50,
              handlechange: onInputChange,
              errormessage: infoErrorMessage?.lastNameError,
              colclassname: "col-sm-12 col-md-4"
            },
            {
              type: "text",
              name: "initial",
              mode: isNameEditable ? "edit" : "view",
              display: lblEditInitial.display,
              texttoread: lblProfileInitialPlaceHolder.display,
              value: sessioncurrentUser?.initial,
              maxLength: 1,
              handlechange: onInputChange,
              errormessage: infoErrorMessage?.initialError,
              colclassname: "col-sm-12 col-md-4"
            }
          ],
        [
          {
            type: "text",
            name: "dateOfBirth",
            display: lblBirthDate.display,
            texttoread: lblBirthDate.textToRead,
            mode: "view",
            datetime: sessioncurrentUser?.dateOfBirth?.display,
            colclassname: "col-sm-12 col-md-6",
            fgclass: "peronal-info-details",
            lblclassname: "edit-input-label"
          },
          {
            type: "select",
            name: "gender",
            classname: "form-control login-input-field",
            display: lblGender.display,
            texttoread: lblGender.textToRead + " " + profileData?.genderText,
            mode: "view",
            value: profileData?.genderText,
            handlechange: onInputChange,
            colclassname: "col-sm-12 col-md-6",
            fgclass: "peronal-info-details",
            lblclassname: "edit-input-label"
          },
          profileData?.maritalStatusText && {
            type: "select",
            name: "maritalStatus",
            display: lblMaritalStatus.display,
            texttoread: lblMaritalStatus.textToRead + " " + profileData?.maritalStatusText,
            mode: infoMode,
            selectedvalue: sessioncurrentUser?.maritalStatus,
            value: profileData?.maritalStatusText,
            options: profileData?.maritalStatusList,
            handlechange: onInputChange,
            colclassname: "col-sm-12 col-md-6",
            fgclass: "peronal-info-details",
            classname: "login-input-field"
          },
          {
            type: "select",
            name: "language",
            mode: "view",
            display: lblLanguage.display,
            texttoread: lblLanguage.textToRead + " " + profileData?.languageText,
            selectedvalue: sessioncurrentUser?.languageCode,
            value: profileData?.languageText,
            options: profileData?.languageList,
            colclassname: "col-sm-12 col-md-6",
            fgclass: "peronal-info-details"
          },
          {
            type: "text",
            name: "salary",
            mode: "view",
            skip: !profileData?.showEarningsInProfile ? "1" : null,
            display: profileData?.salaryLabel,
            texttoread: profileData?.salaryLabel + " " + profileData?.salaryText,
            value: profileData?.salaryText,
            colclassname: "col-sm-12 col-md-6",
            fgclass: "peronal-info-details"
          },
          {
            type: "text",
            name: "address",
            skip: infoMode == "edit" && isAddressEditable ? "1" : null,
            mode: "view",
            display: lblEditAddress?.display,
            value: addressText,
            colclassname: "col-sm-12 col-md-6",
            fgclass: "peronal-info-details",
            lblclassname: "float-left ps-1"
          },
          isAddressEditable && {
            type: "text",
            fgclass: "peronal-info-details",
            display: lblEditAddress?.display,
            texttoread: lblEditAddress?.textToRead,
            mode: "edit",
            colclassname: "col-sm-12 col-md-6",
            skip: infoMode == "view" ? "1" : null,
            textGroup: [
              {
                type: "text",
                name: "address1",
                texttoread: lblProfileAddress1PlaceHolder?.textToRead,
                value: address?.address1,
                maxLength: 50,
                handlechange: onInputChangeAddress,
                errormessage: infoErrorMessage?.address1Error,
                classname: "login-input-field mb-3"
              },
              {
                type: "text",
                name: "address2",
                texttoread: lblProfileAddress2PalceHolder?.textToRead,
                value: address?.address2,
                maxLength: 50,
                handlechange: onInputChangeAddress,
                errormessage: infoErrorMessage?.address2Error,
                classname: "login-input-field mb-3"
              },
              {
                type: "text",
                name: "city",
                texttoread: lblProfileCityPlaceHolder?.textToRead,
                value: address?.city,
                maxLength: 50,
                handlechange: onInputChangeAddress,
                errormessage: infoErrorMessage?.cityError,
                classname: "col-sm-4 login-input-field edit-input-field mb-3",
                profileWrapper: "postal"
              },
              {
                type: "select",
                name: "provinceCode",
                showvalue: true,
                selectedvalue: address?.provinceCode,
                value: address?.provinceCode,
                options: profileData?.provincesList,
                handlechange: onInputChangeAddress,
                classname: "mb-3 form-select"
              },
              {
                type: "text",
                name: "postalCode",
                texttoread: lblProfilePostalCodePlaceHolder?.textToRead,
                value: address?.postalCode,
                maxLength: 8,
                handlechange: onInputChangeAddress,
                errormessage: infoErrorMessage?.postalCodeError,
                classname: "login-input-field",
                profileWrapper: "postal"
              },
              {
                type: "select",
                name: "countryCode",
                showvalue: true,
                selectedvalue: address?.countryCode,
                value: address?.countryCode,
                options: profileData?.countriesList,
                handlechange: onInputChangeAddress,
                classname: "login-input-field form-select"
              }
            ]
          }
        ]
      ]
    },
    footer: {
      showFooter: infoMode == "edit" ? true : false,
      footerData: [
        {
          variant: "outline-secondary btn-sm btn-cancel",
          display: btnCancel?.display,
          onSubmit: () => cancel("info")
        },
        {
          variant: "outline-secondary btn-sm btn-save float-end",
          display: btnSave?.display,
          disabled: !infoErrorMessage?.isValid,
          onSubmit: () => handleSaveClick("info")
        }
      ]
    },
    errorList: infoMode === "edit" ? errorList : null
  };

  const phoneObject = {
    head: {
      gridtitle: pnlPhoneGroupingText?.display,
      texttoread: editIcon.textToRead + " " + pnlPhoneGroupingText.textToRead,
      showediticon: showPhoneEditable,
      editbtndata: {
        className: "link icon-action edit-icon float-right",
        ariaLabel: editIcon.textToRead + " " + profileData?.employeeNameText,
        onSubmit: reversePhoneMode
      }
    },
    body: {
      gridRowData: [
        (isHomePhoneEditable || profileData?.homePhoneText) && [
          { rowclassname: "mb-3" },
          {
            type: "text",
            name: "homePhone",
            display: phoneMode == "view" ? lblHomePhone?.display : lblEditHomePhone?.display,
            mode: "view",
            colclassname: phoneMode == "view" ? "col-sm-12 col-md-2" : "col-sm-12 col-md-3"
          },
          {
            type: "text",
            name: "areaCode",
            texttoread: lblProfileHomePhoneAreaCodePlaceHolder.textToRead,
            mode: isHomePhoneEditable && phoneMode == "edit" ? "edit" : "view",
            maxLength: 3,
            errormessage: phoneErrorMessage?.homeAreaCodeError,
            display: isHomePhoneEditable && phoneMode == "edit" ? null : profileData?.homePhoneText,
            value: phoneMode == "view" ? null : sessioncurrentUser?.homePhone?.areaCode,
            handlechange: changeHomePhone,
            // colclassname: phoneMode == "view" ? "col-sm-12 col-md-9" : "col-sm-12 col-md-3"
            colclassname: isHomePhoneEditable && phoneMode == "edit" ? "col-sm-12 col-md-3" : "col-sm-12 col-md-9"
          },
          {
            type: "text",
            name: "phoneNumber",
            texttoread: lblProfileHomePhonePhoneNumberPlaceHolder.textToRead,
            mode: isHomePhoneEditable && phoneMode == "edit" ? "edit" : "view",
            maxLength: 7,
            errormessage: phoneErrorMessage?.homePhoneNumberError,
            skip: isHomePhoneEditable && phoneMode == "edit" ? null : "1",
            value: sessioncurrentUser?.homePhone?.phoneNumber,
            handlechange: changeHomePhone,
            colclassname: "col-sm-12 col-md-3"
          }
        ],
        (showWorkPhone || profileData?.workPhoneText) && [
          {
            type: "text",
            name: "workPhone",
            display: phoneMode == "view" ? lblWorkPhone?.display : lblEditWorkPhone?.display,
            mode: "view",
            colclassname: phoneMode == "view" ? "col-sm-12 col-md-2" : "col-sm-12 col-md-3"
          },
          {
            type: "text",
            name: "areaCode",
            texttoread: lblProfileHomePhoneAreaCodePlaceHolder.textToRead,
            mode: isWorkPhoneEditable && phoneMode == "edit" ? "edit" : "view",
            maxLength: 3,
            errormessage: phoneErrorMessage?.workAreaCodeError,
            display: phoneMode == "view" ? profileData?.workPhoneText : null,
            value: phoneMode == "view" ? null : sessioncurrentUser?.workPhone?.areaCode,
            handlechange: changeWorkPhone,
            colclassname: isWorkPhoneEditable && phoneMode == "edit" ? "col-sm-12 col-md-3" : "col-sm-12 col-md-9"
          },
          {
            type: "text",
            name: "phoneNumber",
            texttoread: lblProfileHomePhonePhoneNumberPlaceHolder.textToRead,
            mode: phoneMode,
            maxLength: 7,
            errormessage: phoneErrorMessage?.workPhoneNumberError,
            // skip: phoneMode == "view" ? "1" : null,
            skip: isWorkPhoneEditable && phoneMode == "edit" ? null : "1",
            value: sessioncurrentUser?.workPhone?.phoneNumber,
            handlechange: changeWorkPhone,
            colclassname: "col-sm-12 col-md-3"
          },
          {
            type: "text",
            name: "extension",
            maxLength: 5,
            texttoread: lblProfileHomePhonePhoneNumberPlaceHolder.textToRead,
            mode: phoneMode,
            errormessage: phoneErrorMessage?.workextensionError,
            //skip: phoneMode == "view" ? "1" : null,
            skip: isWorkPhoneEditable && phoneMode == "edit" ? null : "1",
            value: sessioncurrentUser?.workPhone?.extension,
            handlechange: changeWorkPhone,
            colclassname: "col-sm-12 col-md-3"
          }
        ]
      ]
    },
    footer: {
      showFooter: phoneMode == "edit" ? true : false,
      footerData: [
        {
          variant: "outline-secondary btn-sm btn-cancel",
          display: btnCancel?.display,
          onSubmit: () => cancel("phone")
        },
        {
          variant: "outline-secondary btn-sm btn-save float-end",
          display: btnSave?.display,
          disabled: !phoneErrorMessage?.isValid,
          onSubmit: () => handleSaveClick("phone")
        }
      ]
    },
    errorList: phoneMode === "edit" ? errorList : null
  };

  const emailObject = {
    head: {
      gridtitle: pnlEmailGroupingText?.display,
      texttoread: editIcon.textToRead + " " + pnlEmailGroupingText.textToRead,
      showediticon: showEmailEditable,
      editbtndata: {
        className: "link icon-action edit-icon float-right",
        ariaLabel: editIcon.textToRead + " " + profileData?.employeeNameText,
        onSubmit: reverseEmailMode
      }
    },
    body: {
      bodytitle: showEmailEditable && emailMode == "edit" ? lblPreferredEmail.display : "",
      viewbodyMode: "email",
      gridRowData: [
        (emailMode == "view" ? isWorkEmailEditable || profileData?.workEmailText : showEmailEditable) && [
          {
            type: "text",
            name: "lblWorkEmail",
            display: lblWorkEmail.display,
            mode: "view",
            skip: emailMode == "view" ? null : "1",
            colclassname: emailMode == "view" ? "col-sm-12 col-md-2" : "col-sm-12 col-md-3"
          },
          {
            type: "choicelist",
            skip: emailMode == "view" ? "1" : null,
            colclassname: "col-sm-12 col-md-4",
            mode: emailMode,
            options: {
              type: "radio",
              fgclass: "mt-2 mb-4",
              name: "selectedWorkEmail",
              handlechange: changePreferredEmailStatus,
              selected: sessioncurrentUser?.selectedEmail,
              optionsinput: [
                {
                  text: lblEditWorkEmail.display,
                  value: "work"
                }
              ]
            }
          },
          (isWorkEmailEditable || profileData?.workEmailText) && {
            type: "text",
            name: "workEmail",
            texttoread: lblProfileWorkEmailPlaceHolder.textToRead,
            mode: isWorkEmailEditable && emailMode == "edit" ? "edit" : "view",
            maxLength: 100,
            errormessage: emailErrorMessage?.workemailError,
            display: emailMode == "view" ? profileData?.workEmailText : null,
            value: isWorkEmailEditable && emailMode == "edit" ? sessioncurrentUser?.workEmail : null,
            handlechange: onInputChange,
            colclassname: emailMode == "view" ? "col-sm-12 col-md-8" : "col-sm-12 col-md-7"
          }
        ],
        (emailMode == "view" ? isHomeEmailEditable || profileData?.personalEmailText : showEmailEditable) && [
          {
            type: "text",
            name: "lblPersonalEmail",
            display: lblPersonalEmail.display,
            mode: "view",
            skip: emailMode == "view" ? null : "1",
            colclassname: emailMode == "view" ? "col-sm-12 col-md-2" : "col-sm-12 col-md-3"
          },
          {
            type: "choicelist",
            skip: emailMode == "view" ? "1" : null,
            colclassname: "col-sm-12 col-md-4",
            mode: emailMode,
            options: {
              type: "radio",
              fgclass: "mt-2 mb-4",
              name: "selectedpersonalEmail",
              selected: sessioncurrentUser?.selectedEmail,
              handlechange: changePreferredEmailStatus,
              optionsinput: [
                {
                  text: lblEditPersonalEmail.display,
                  value: "personal"
                }
              ]
            }
          },
          (isHomeEmailEditable || profileData?.personalEmailText) && {
            type: "text",
            name: "personalEmail",
            texttoread: lblProfilePersonalEmailPlaceHolder.textToRead,
            mode: isHomeEmailEditable && emailMode == "edit" ? "edit" : "view",
            maxLength: 100,
            errormessage: emailErrorMessage?.personalemailError,
            display: emailMode == "view" ? profileData?.personalEmailText : null,
            value: isHomeEmailEditable && emailMode == "edit" ? sessioncurrentUser?.personalEmail : null,
            handlechange: onInputChange,
            colclassname: emailMode == "view" ? "col-sm-12 col-md-7" : "col-sm-12 col-md-7"
          }
        ]
      ]
    },
    footer: {
      showFooter: emailMode == "edit" ? true : false,
      footerData: [
        {
          variant: "outline-secondary btn-sm btn-cancel",
          display: btnCancel?.display,
          onSubmit: () => cancel("email")
        },
        {
          type: "submit",
          variant: "outline-secondary btn-sm btn-save float-end",
          display: btnSave?.display,
          onSubmit: () => handleSaveClick("email")
        }
      ]
    },
    errorList: emailMode === "edit" ? errorList : null
  };

  const smokerList = [
    {
      text: lblNonSmoker,
      value: 0
    },
    {
      text: lblSmoker,
      value: 1
    }
  ];

  const smokerStatusObject = (!profileData?.readOnly || sessioncurrentUser?.smokerStatus?.toString() === "0" || sessioncurrentUser?.smokerStatus?.toString() === "1") &&
    !sessioncurrentUser?.smokerDeclarationOptional && {
      cardClass: "h-100",
      head: {
        gridtitle: smokerStatusTitle?.display
      },
      body: {
        gridRowData: [
          [
            !profileData?.readOnly
              ? {
                  type: "choicelist",
                  colclassname: "col-sm-12 col-md-12",
                  mode: "edit",
                  options: {
                    type: "radio",
                    legendtext: smokerStatusMessage.display,
                    name: "reviewProfileSmokerStatus",
                    selected: sessioncurrentUser?.smokerStatus?.toString(),
                    width: "50",
                    fgclass: "float-left",
                    handlechange: changeSmokerStatus,
                    optionsinput: smokerList,
                    wrapperclass: "mb-3"
                  }
                }
              : {
                  type: "text",
                  name: "reviewProfileSmokerStatus",
                  mode: "view",
                  display: smokerStatusMessage.display,
                  colclassname: "col-sm-12 col-md-12"
                },

            !profileData?.readOnly &&
              sessioncurrentUser?.smokerStatus === 0 &&
              profileData?.showSmokerDeclaration &&
              smokerDeclarationChanged && {
                colclassname: "mt-4",
                classname: "primary",
                type: "instructions",
                display: profileData?.smokerDeclarationLabel
              }
          ]
        ]
      }
    };

  return (
    <>
      <div className="row">
        <div className="container">
          {profileData ? (
            <>
              {changeNote && <AlertComponent marginleft={false} propmessageType="light" propmessage={changeNote} />}
              {/*start Info,Address GridComponent */}
              {infoObject && (
                <div className="row">
                  <div className="w-100">
                    <CardComponent gridObject={infoObject}></CardComponent>
                  </div>
                </div>
              )}
              {/*end Info,Address GridComponent  */}

              {/*start Phone GridComponent */}
              {phoneObject && (
                <div className="row">
                  <div className="w-100">
                    <CardComponent gridObject={phoneObject}></CardComponent>
                  </div>
                </div>
              )}
              {/*end Phone GridComponent  */}

              {/*start Email GridComponent */}
              {emailObject && (
                <div className="row">
                  <div className="w-100">
                    <CardComponent gridObject={emailObject}></CardComponent>
                  </div>
                </div>
              )}
              {/*end email GridComponent  */}

              {/*start smoker status GridComponent */}
              {smokerStatusObject && (
                <div className="row">
                  <div className="w-100 pb-4">
                    <CardComponent gridObject={smokerStatusObject}></CardComponent>
                  </div>
                </div>
              )}
              {/*end smoker status GridComponent  */}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ReviewPersonalInfo;
