import { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dissmiss,getForgotPasswordContent, showError, showSuccess } from "../../actions";
import { resetPassword } from "../../actions/AuthActions";
//import { FormComponent, DescriptionComponent } from "../../components";
import { PreloginComponent } from "../../components/Auth";
import { sessionForgotPassword } from "../../interfaces/formValidation";
import { ObjectUtils } from "../../utils";
import { USER_TOKEN } from "../../utils/Storage";
import { RootState } from "../../reducers";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const textInputRefs = useRef<HTMLInputElement>();
  const { client } = USER_TOKEN.get();
  const dispatch = useDispatch();
  const forgotPassword = useSelector((state: RootState) => state.common.forgotPasswordContent);
  const [sessionForgotPassword, setsessionForgotPassword] = useState<sessionForgotPassword>({
    username: "",
    formValidated: false
  });

  const onInputChange = (value: any) => {
    setsessionForgotPassword({
      ...sessionForgotPassword,
      ...value,
      formValidated: false
    });
  };

  const handleKeyDown = (event: FormEvent<EventTarget>): void => {
    handleSubmit(event);
  };

  const handleCancel = () => {
    dissmiss();
    navigate(`/${client}/Login`);
  };

  const handleSubmit = (event: FormEvent<EventTarget>): void => {
    event.preventDefault();
    if (!sessionForgotPassword.username) {
      setsessionForgotPassword({
        ...sessionForgotPassword,
        formValidated: true
      });
      textInputRefs.current?.focus();
    } else {
      dispatch(
        resetPassword(
          sessionForgotPassword.username,
          (response) => {
            if (response.statusCode === 200) {
              showSuccess(response.result.Message);
              navigate(`/${client}/Login`);
            } else {
              showError(response.errorMessage);
            }
          },
          (errorMessage) => {
            showError(errorMessage);
          }
        )
      );
    }
  };

  /**
   * This function will execute once this component
   * has been mounted.
   */
  useEffect(() => {
    dissmiss();
    //This gets app config data and save into redux store
    if(!forgotPassword){  
      dispatch(getForgotPasswordContent());
      
    }
  }, [dispatch, forgotPassword]);

  const contents = forgotPassword?.contentResource?.contents;
  const headerPage = ObjectUtils.extractLabelFromObject(contents, "lblPageTitleForgot");
  const description = ObjectUtils.extractLabelFromObject(contents, "lblForgotPassword");
  const description2 = ObjectUtils.extractLabelFromObject(contents, "lblForgotPassword1");
  const lblUserName = ObjectUtils.extractLabelFromObject(contents, "lblLogin");
  const lblSubmit = ObjectUtils.extractLabelFromObject(contents, "lblSubmit");
  const lblCancel = ObjectUtils.extractLabelFromObject(contents, "lblCancel") || {
    textToRead: "Cancel",
    display: "Cancel"
  };
  const rfvLoginInErrorMessage = ObjectUtils.extractLabelFromObject(contents, "rfvLoginInErrorMessage");
  document.title = headerPage.display;

  const loginAuthObj = {
    description: {
      headingclassname: "login-heading d-md-block",
      level: "1",
      heading: headerPage.display,
      classname: "d-md-none",
      description: description.display,
      description2: description2.display
    },
    noHeadingDesciptions: {
      classname: "holder d-none d-md-block",
      description: description?.display,
      description2: description2?.display
    },
    formInput: {
      texttoread: headerPage.textToRead,
      classname: "loginform",
      validated: sessionForgotPassword.formValidated,
      handlesubmit: handleSubmit
    },
    textInput: [
      {
        type: "text",
        mode: "edit",
        name: "username",
        handlechange: onInputChange,
        handlekeydown: handleKeyDown,
        classname: "form-control login-input-field",
        fgclass: "mb-3",
        display: lblUserName.display,
        labeltext: lblUserName.display,
        texttoread: lblUserName.textToRead,
        error: rfvLoginInErrorMessage.display,
        refInput: textInputRefs
      }
    ],
    buttonInput: [
      lblCancel.display && {
        variant: "outline-secondary btn-cancel",
        display: lblCancel.display,
        onSubmit: handleCancel
      },
      lblSubmit.display && {
        type: "submit",
        variant: "secondary btn-submit float-end",
        display: lblSubmit.display
      }
    ]
  };

  return forgotPassword ? <PreloginComponent loginAuthObj={loginAuthObj}></PreloginComponent> : null;
};

export default ForgotPassword;
