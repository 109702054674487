import { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { dissmiss, getClientById, getLoginContent, loading, showError } from "../../actions";
import { authenticate } from "../../actions/AuthActions";
import { PreloginComponent } from "../../components/Auth";
import { sessionLoginPassword } from "../../interfaces/formValidation";
import { RootState } from "../../reducers";
import { ObjectUtils, USER_TOKEN } from "../../utils";

export const LoginCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const textInputRefs = useRef<HTMLInputElement[]>([]);
  // const [clientStyles, setClientStyles] = useState<EnumClientStyles>();
  const { contentFooter } = useSelector((state: RootState) => state.common);
  const loginContent = useSelector((state: RootState) => state.common.contentLogin);
  const { isSignedIn, authResponse, needTwoFARegistration, needTwoFAValidation, redirectURL } = useSelector((state: RootState) => state.auth);
  const footerContent = contentFooter?.contentResource?.contents || {};
  const sessionExpiredMessage = ObjectUtils.extractLabelFromObject(footerContent, "SessionExpiredMessage");

  if (redirectURL) {
    navigate("/outside");
  }

  // const [loginContent, setLoginContent] = useState<EnumLoginContent>();
  const [sessionLoginPassword, setsessionLoginPassword] = useState<sessionLoginPassword>({
    formValidated: false,
    username: "",
    password: ""
  });

  const onInputChange = (value: any) => {
    setsessionLoginPassword({
      ...sessionLoginPassword,
      ...value,
      formValidated: false
    });
  };

  const isValidation = () => {
    let isValid = false;
    if (!sessionLoginPassword.username) {
      setsessionLoginPassword({
        ...sessionLoginPassword,
        formValidated: true
      });
      textInputRefs.current[0]?.focus();
      isValid = true;
    } else if (!sessionLoginPassword.password) {
      setsessionLoginPassword({
        ...sessionLoginPassword,
        formValidated: true
      });
      textInputRefs.current[1]?.focus();
      isValid = true;
    }
    return isValid;
  };

  const handleKeyDown = (event: FormEvent<EventTarget>): void => {
    handleSubmit(event);
  };

  const handleSubmit = async (event: FormEvent<EventTarget>): Promise<void> => {
    event.preventDefault();
    dissmiss();
    if (!isValidation()) {
      //This  tfaDate need to change logic after 2fa
      let tfaDate: any = JSON.parse(localStorage.getItem("tfaDate") || "{}") || "";
      if (tfaDate === null || Object.keys(tfaDate).length === 0) {
        const cdt = new Date();
        tfaDate = {
          day: cdt.getDate(),
          month: cdt.getMonth() + 1,
          year: cdt.getFullYear()
        };
      }
      // const cdt = new Date();
      // const tfaDate: any = {
      //   day: cdt.getDate(),
      //   month: cdt.getMonth() + 1,
      //   year: cdt.getFullYear()
      // };
      //End
      dispatch(await authenticate(sessionLoginPassword.username, sessionLoginPassword.password, tfaDate,failureCallback));
    }
  };

  const failureCallback = () => {
    loading(false);
    const invalidCredentialsError = ObjectUtils.extractLabelFromObject(contents, "ErrorNoAccess");
    showError(invalidCredentialsError?.display)
  }
  
  const { client } = useParams() as {
    client: string;
  };
  useEffect(() => {
    //This gets app config data and save into redux store
    const clientStyle = JSON.parse(localStorage.getItem("style")!);
    const prevClient = USER_TOKEN?.get().client;
    if ((clientStyle === null || prevClient === null || (prevClient !== null && client.toLocaleLowerCase() !== prevClient?.toLocaleLowerCase())) && client) {
      dispatch(
        getClientById(
          client,
          () => {
            // localStorage.setItem("style", JSON.stringify(response.result));
          },
          (errorMessage) => {
            showError(errorMessage);
            navigate("/403");
          }
        )
      );
    }
  }, [client, dispatch, navigate]);

  useEffect(() => {
    if (isSignedIn) {
      if (authResponse?.passwordChangeRequired) {
        navigate("/changepassword");
      } else if (needTwoFARegistration || needTwoFAValidation) {
        navigate("/multi-factor");
      } else {
        navigate("/");
      }
    }
  }, [authResponse?.passwordChangeRequired, isSignedIn, navigate, needTwoFARegistration, needTwoFAValidation]);

  useEffect(() => {
    client && !loginContent && dispatch(getLoginContent());
  }, [client, dispatch, loginContent]);

  useEffect(() => {
    if (localStorage?.getItem("sessionTimeOut") === "1" && contentFooter) {
      showError(sessionExpiredMessage.display);
      setTimeout(() => {
        localStorage.removeItem("sessionTimeOut");
      }, 2000);
    }
  }, [contentFooter, sessionExpiredMessage.display]);
  const contents = loginContent?.contentResource?.contents;
  const configs = loginContent?.result;
  const forgotPasswordLabel = ObjectUtils.extractLabelFromObject(contents, "lnkForgotPassword");
  const headerPage = ObjectUtils.extractLabelFromObject(contents, "LoginPanelTitle");
  const description = ObjectUtils.extractLabelFromObject(contents, "LoginContentText");
  const lblPageTitle = ObjectUtils.extractLabelFromObject(contents, "lblLoginPageTitle");
  const userIdLabel = ObjectUtils.extractLabelFromObject(contents, "LoinLabelText");
  const passwordLabel = ObjectUtils.extractLabelFromObject(contents, "PasswordText");
  const loginButtonLabel = ObjectUtils.extractLabelFromObject(contents, "btnLogin");
  const userNameEmptyErrorMessage = ObjectUtils.extractLabelFromObject(contents, "rfvtxtLoginErrorMessage");
  const passwordEmptyErrorMessage = ObjectUtils.extractLabelFromObject(contents, "rfvtxtPasswordErrorMessage");
  document.title = lblPageTitle.display && !lblPageTitle.display.includes("no lbl") ? lblPageTitle.display : headerPage.display;

  // console.log("userLanguage",window?.navigator?.language)

  //|| window.navigator.language).split('-')[0]) === 'fr') ? 'fr-CA' : 'en-CA';)
  const loginAuthObj = {
    description: {
      headingclassname: "login-heading d-md-block",
      heading: headerPage.display,
      level: 1,
      classname: "d-md-none",
      description: description.display
    },
    noHeadingDesciptions: {
      classname: "d-none d-md-block",
      description: description.display
    },
    formInput: {
      texttoread: headerPage.textToRead,
      classname: "loginform",
      validated: sessionLoginPassword.formValidated,
      handlesubmit: handleSubmit
    },
    textInput: [
      {
        type: "text",
        name: "username",
        mode: "edit",
        fgclass: "mb-3",
        handlechange: onInputChange,
        classname: "form-control login-input-field",
        display: userIdLabel.display,
        labeltext: userIdLabel.display,
        texttoread: userIdLabel.textToRead,
        error: userNameEmptyErrorMessage.display,
        refInput: (ref: HTMLInputElement) => (textInputRefs.current[0] = ref)
      },
      {
        type: "password",
        name: "password",
        mode: "edit",
        fgclass: "mb-3",
        handlechange: onInputChange,
        handlekeydown: handleKeyDown,
        classname: "form-control login-input-field",
        display: passwordLabel.display,
        labeltext: passwordLabel.display,
        texttoread: passwordLabel.textToRead,
        error: passwordEmptyErrorMessage.display,
        refInput: (ref: HTMLInputElement) => (textInputRefs.current[1] = ref)
      }
    ],
    buttonInput: [
      loginButtonLabel.display && {
        type: "submit",
        variant: "secondary btn-submit w-100",
        display: loginButtonLabel.display
      }
    ],
    forgetPassword: {
      showForgetPassword: configs?.showForgetPassword,
      forgotPasswordLabel: forgotPasswordLabel.display,
      client: client,
      arialabel: forgotPasswordLabel.textToRead
    }
  };

  return loginContent ? <PreloginComponent loginAuthObj={loginAuthObj} /> : null;
};

export default LoginCard;
