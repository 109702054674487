import React, { useEffect, useState } from "react";
import { ObjectUtils } from "../../../utils";
import { Col, Form, Row } from "react-bootstrap";
import { showError, validateTFACode } from "../../../actions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ButtonComponent } from "../../../components";

export const VerificationForm = ({ verifiedContent, resendCodeInterval, resendCount, handleResend }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clicked, setClicked] = useState<boolean>(false);
  const [vfInitialState, setVfInitialState] = useState({
    disabled: false,
    lbldisabled: false,
    time: {
      h: 0,
      m: 0,
      s: 0
    },
    seconds: resendCodeInterval,
    otpCode: "",
    errorMessage: "",
    inActive: true
  });
  //let timer: number | NodeJS.Timer = 0;

  const failureCallback = (msg) => {
    showError(msg ? msg : "Please enter a valid verification code.");
  };

  const secondsToTime = (secs) => {
    const hours = Math.floor(secs / (60 * 60));

    const divisor_for_minutes = secs % (60 * 60);
    const minutes = Math.floor(divisor_for_minutes / 60);

    const divisor_for_seconds = divisor_for_minutes % 60;
    const seconds = Math.ceil(divisor_for_seconds);

    const obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  };

  const resetTimer = () => {
    setClicked(true);
    setVfInitialState((prevState) => ({
      ...prevState,
      seconds: resendCodeInterval,
      disabled: true,
      lbldisabled: true,
      errorMessage: ""
    }));
  };
  useEffect(() => {
    let interval: any;
    if (clicked && vfInitialState.seconds > 0) {
      interval = setInterval(() => {
        const _seconds = vfInitialState.seconds - 1;
        setVfInitialState({
          ...vfInitialState,
          time: secondsToTime(_seconds),
          seconds: _seconds
        });
      }, 1000);
    }
    // Check if we're at zero.
    else if (vfInitialState.seconds === 0) {
      setClicked(false);
      setVfInitialState({
        ...vfInitialState,
        disabled: false,
        seconds: resendCodeInterval
      });
      clearInterval(interval);
    }
    // Clear the interval when the component is unmounted or when the timer reaches 0
    return () => clearInterval(interval);
  }, [clicked, resendCodeInterval, vfInitialState]);

  const setOtpCode = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setVfInitialState((prevState) => ({
        ...prevState,
        otpCode: e.target.value
      }));
      if (e.target.value.length === 6) {
        setVfInitialState((prevState) => ({
          ...prevState,
          inActive: false
        }));
      } else {
        setVfInitialState((prevState) => ({
          ...prevState,
          inActive: true
        }));
      }
    }
  };

  /* const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.charCode === 13) {
      handleSubmit(event);
    }
  }; */

  const handleSubmit = async (event) => {
    event.preventDefault();
    const options = {
      Code: vfInitialState.otpCode,
      IsRegistration: true,
      TypeID: parseInt(verifiedContent?.twoFATypeID)
    };
    dispatch(await validateTFACode(options, navigate, failureCallback));
  };

  useEffect(() => {
    if (resendCodeInterval !== vfInitialState?.seconds) {
      // const timeLeftVar = secondsToTime(resendCodeInterval);
      // setVfInitialState((prevState) => ({
      //   ...prevState,
      //   time: timeLeftVar,
      //   //seconds: resendCodeInterval
      // }));
    }
  }, []);

  const content = verifiedContent?.contentResource?.contents;

  const btnVerifyCode = ObjectUtils.extractLabelFromObject(content, "btnVerifyCode");
  const btnResend = ObjectUtils.extractLabelFromObject(content, "btnResend");
  const lblVerificationMsgReceived = ObjectUtils.extractLabelFromObject(content, "lblVerificationMsgReceived");
  const lblDetail2 = ObjectUtils.extractLabelFromObject(content, "lblDetail2");
  const lblResendVerificationMsg = ObjectUtils.extractLabelFromObject(content, "lblResendVerificationMsg");
  const lblTitle = ObjectUtils.extractLabelFromObject(content, "lblTitle");
  document.title = lblTitle?.display;
  const lblverificationCode = ObjectUtils.extractLabelFromObject(content, "lblverificationCode");
  const { isAllowedToResendCode, maxResendPerSession } = verifiedContent;

  return (
    <div className="conatiner" role="main" id="maincontent">
      <div className="login-holder">
        <Row className="h-100">
          <div className="col-12 login-image-holder"></div>
          <div className="logincontainer d-md-flex align-items-center align-content-center justify-content-between">
            <div className="holder login-form description panel d-none d-md-block">
              <span dangerouslySetInnerHTML={{ __html: lblDetail2.display }} />
            </div>
            <div className="form-group login-form mobile-login-form pb-5 pb-md-0">
              <h1 className="login-heading d-md-block">{lblTitle.display}</h1>
              <div className="login-form description panel d-md-none">
                <span dangerouslySetInnerHTML={{ __html: lblDetail2.display }} />
              </div>
              <div className="holder">
                <div id="login-container" className="loginform">
                  <Row>
                    <Col className="col-12 paddingbottom">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: lblVerificationMsgReceived.display
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: lblResendVerificationMsg.display
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="col-12 paddingtop">
                      <label className="label-heading mb-2">{lblverificationCode.display}</label>
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        placeholder={lblverificationCode.display}
                        className="form-control login-input-field"
                        value={vfInitialState.otpCode}
                        minLength={6}
                        maxLength={6}
                        required
                        onChange={setOtpCode}
                      />
                    </Col>

                    <Col className="col-12 paddingtop">
                      <div className={`btns-forgot d-flex justify-content-between`}>
                        <ButtonComponent
                          btndata={[
                            {
                              variant: "secondary",
                              display: btnVerifyCode?.display,
                              onSubmit: handleSubmit,
                              disabled: vfInitialState.inActive,
                              type: "submit"
                            }
                          ]}
                        />

                        {isAllowedToResendCode && maxResendPerSession > resendCount && (
                          <ButtonComponent
                            btndata={[
                              {
                                variant: "outline-secondary",
                                display: (
                                  <>
                                    {btnResend.display}
                                    {vfInitialState?.time?.s > 0 && vfInitialState.time?.s < resendCodeInterval && ` (${vfInitialState.time.s.toString().padStart(2, "0")} )`}
                                  </>
                                ),
                                onSubmit: () => {
                                  handleResend();
                                  resetTimer();
                                },
                                disabled: vfInitialState.disabled
                              }
                            ]}
                          />
                        )}
                      </div>
                    </Col>

                    {vfInitialState?.errorMessage && (
                      <Col>
                        <div className="alert alert-danger mt-4" role="alert">
                          <p className="m-0">{vfInitialState.errorMessage}</p>
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default VerificationForm;
