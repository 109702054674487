import { useCallback, useEffect, useState } from "react";
import { deleteDependent, disableNext, enableNext, getDependents, setNavigationError, showError, updateDependent } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AlertComponent, ButtonComponent, CardComponent } from "../../components";
import { ModalComponent } from "../../components/Modal";
import { RootState } from "../../reducers";
import { ObjectUtils } from "../../utils";
import AddNewDependent from "./AddNewDependent";

export const Dependents = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const typeParam = searchParams.get("type");
  const newDependentType = typeParam ?? "";
  const [dependentType, setdependentType] = useState<string>("");
  const [showRemoveConfirmModal, setshowRemoveConfirmModal] = useState<boolean>();
  const [dependentId, setdependentId] = useState<any>(-1);

  // const [showNavigationError, setNavigationError] = useState<boolean>(false);
  const [smokerDeclarationChanged, setsmokerDeclarationChanged] = useState<boolean>(true);
  const [showAddNewDependentModal, setShowAddNewDependentModal] = useState<boolean>(false);
  const { contentFooter } = useSelector((state: RootState) => state.common);
  const { nextEnrolStep } = useSelector((state: RootState) => state.event);
  const dependentsData = useSelector((state: RootState) => state.profile.dependents);
  const [dependentList, setDependentList] = useState<any[]>([]);
  const [studentValidErrorList, setStudentValidErrorList] = useState<any[]>([]);
  useEffect(() => {
    dependentsData && setDependentList(dependentsData?.result?.Dependents || []);
  }, [dependentsData]);

  const loadDependents = useCallback(
    async (depType: string) => {
      dispatch(await getDependents(depType));
      if (!(nextEnrolStep.navigationError === undefined || nextEnrolStep.navigationError === null)) {
        setNavigationError(null);
      }
    },
    [dispatch, nextEnrolStep.navigationError]
  );

  const updatedDependent = useCallback(
    (currentUser: any) => {
      dispatch(
        updateDependent(
          currentUser,
          dependentType,
          () => {
            loadDependents(dependentType);
          },
          (errorMessage) => {
            showError(errorMessage);
          }
        )
      );
    },
    [dependentType, dispatch, loadDependents]
  );

  useEffect(() => {
    setdependentType(newDependentType);
    loadDependents(newDependentType);
  }, [newDependentType]);

  const showDeleteConfirmModal = (dependentID: any) => {
    setshowRemoveConfirmModal(true);
    setdependentId(dependentID);
  };

  const handleClose = () => {
    setshowRemoveConfirmModal(false);
    setdependentId(-1);
  };

  const filteredDependent = (dependentId: number) => {
    const dependents = dependentsData?.result?.Dependents || [];
    const dependent = dependents.filter((item: { dependentID: number }) => item.dependentID == dependentId); //7047
    return dependent[0];
  };

  const changeCoverUnder = (callback: any) => {
    const key: any = Object.keys(callback)[0].split("_")[0];
    const value: any = Object.values(callback)[0];
    const dependent: any = filteredDependent(callback?.dependentID);
    const changedValues: any = { [key]: value };
    const currentUser = { ...dependent, ...changedValues };
    updatedDependent(currentUser);
  };

  const changeStudentStatus = (callback: any) => {
    enableNext();
    const value: any = Object.values(callback)[0];
    const currentIndex = dependentList.findIndex((item: any) => item.dependentID == callback?.dependentID);
    const _updatedDependent = Object.assign({}, dependentList[currentIndex]);
    _updatedDependent["isStudentStatusConfirmed"] = value;
    _updatedDependent["isChildRelationshipEditable"] = false;
    const newdependentList = dependentList.slice();
    newdependentList[currentIndex] = _updatedDependent;
    setDependentList(newdependentList);
    updatedDependent(_updatedDependent);
  };

  const changeSmokerStatus = (callback: any) => {
    const dependentList = dependentsData?.result?.Dependents || [];
    const value: any = Object.values(callback)[0];
    const currentIndex = dependentList?.findIndex((item: any) => item.dependentID == callback?.dependentID);

    const _updatedDependent = Object.assign({}, dependentList[currentIndex]);
    _updatedDependent["smokerStatus"] = parseInt(value);
    const newdependentList = dependentList.slice();
    newdependentList[currentIndex] = _updatedDependent;

    setDependentList(newdependentList);
    setsmokerDeclarationChanged(true);
    updatedDependent(_updatedDependent);
  };

  const changeRelationID = (callback: any) => {
    const relationshipList = dependentsData?.relationshipList || [];
    const value: any = Object.values(callback)[0];
    const studentID = relationshipList.find((item: any) => item.type == "Student").value;
    const currentIndex = dependentList.findIndex((item: any) => item.dependentID == callback?.dependentID);
    const _updatedDependent = Object.assign({}, dependentList[currentIndex]);
    _updatedDependent["relationshipID"] = parseInt(value);
    _updatedDependent["type"] = "Student";
    const newdependentList = dependentList.slice();
    newdependentList[currentIndex] = _updatedDependent;
    setDependentList(newdependentList);
    let isValid: any = false;
    if (_updatedDependent["relationshipID"] === parseInt(studentID)) {
      isValid = true;
      disableNext();
    } /*  else {
      isValid = false;
      enableNext();
    } */
    if (_updatedDependent["relationshipID"] !== parseInt(studentID)) {
      isValid = false;
      enableNext();
    }
    setStudentDependentValidation(isValid, callback?.dependentID);
  };

  const setStudentDependentValidation = (isValid: any, dependentID: number) => {
    const _studentValidErrorList: any[] = [...studentValidErrorList];
    const index: any = _studentValidErrorList?.findIndex((item) => item.dependentID === dependentID);
    if (index > -1) {
      _studentValidErrorList.forEach((opt: any) => {
        if (opt.dependentID === dependentID) opt.studentValid = isValid;
      });
    }
    index < 0 && _studentValidErrorList.push({ studentValid: isValid, dependentID: dependentID });
    setStudentValidErrorList(_studentValidErrorList);
  };

  const handleDeleteDependent = () => {
    dispatch(
      deleteDependent(
        dependentId,
        (response) => {
          console.log(response);
          handleClose();
          loadDependents(dependentType);
        },
        (errorMessage) => {
          handleClose();
          showError(errorMessage);
        }
      )
    );
  };
  const footerContent = contentFooter?.contentResource?.contents || {};
  const btnCancel = ObjectUtils.extractLabelFromObject(footerContent, "btnCancel");
  const deleteIcon = ObjectUtils.extractLabelFromObject(footerContent, "DeleteIcon");
  const contents1 = dependentsData?.contentResource?.contents || [];
  const contents2 = dependentsData?.contentResource?.controlResource?.DependentInfo || [];
  const contents: any = [...contents1, ...contents2];
  const lblNoDependentsText = ObjectUtils.extractLabelFromObject(contents, "lblNoDependentsText");
  const lblYoucanaddordeleteyourdependent = ObjectUtils.extractLabelFromObject(contents, "lblYoucanaddordeleteyourdependent");
  const lblRelationshipText = ObjectUtils.extractLabelFromObject(contents, "lblRelationshipText");
  const lblBirthDateText = ObjectUtils.extractLabelFromObject(contents, "lblBirthDateText");
  const lblGenderText = ObjectUtils.extractLabelFromObject(contents, "lblGenderText");
  /**
   *
   * lblDeleteDependentConfirmBtn
   * lblDeleteDependentHeader
   * lblDeleteDependentConfirmation
   * lblDeleteDependentConfirmation2
   */
  const lblDeleteDependentHeader = ObjectUtils.extractLabelFromObject(contents, "lblDeleteDependentHeader");
  const lblDeleteDependentConfirmBtn = ObjectUtils.extractLabelFromObject(contents, "lblDeleteDependentConfirmBtn");
  const lblDeleteDependentConfirmation = ObjectUtils.extractLabelFromObject(contents, "lblDeleteDependentConfirmation");
  const lblDeleteDependentConfirmation2 = ObjectUtils.extractLabelFromObject(contents, "lblDeleteDependentConfirmation2");
  const lblStudentNeedToRemoveDuringLEorAE = ObjectUtils.extractLabelFromObject(contents, "lblStudentNeedToRemoveDuringLEorAE");
  const lblStudentDeclarationText = ObjectUtils.extractLabelFromObject(contents, "lblStudentDeclarationText");
  const lblCoveredThisPlanCaptionText = ObjectUtils.extractLabelFromObject(contents, "lblCoveredThisPlanCaptionText");
  const lblCoveredOtherPlanCaptionText = ObjectUtils.extractLabelFromObject(contents, "lblCoveredOtherPlanCaptionText");

  const { readOnly, smokerDeclarationLabel, spousalSmokerDeclarationOptional, isAllowUpdateSudentStatus, canSpouseAdded } = dependentsData || {};

  const smokerStatusList = dependentsData?.smokerStatusList || [];
  const relationshipList = dependentsData?.relationshipList || [];
  const relationshipListDisplay = dependentsData?.relationshipListDisplay || [];
  const canBeRemoved = dependentType === "updatestudent" ? false : true;

  const nonSmokerMessage = ObjectUtils.extractLabelFromObject(contents, "lblIsNonSmokerText");
  const smokerMessage = ObjectUtils.extractLabelFromObject(contents, "lblIsSmokerText");

  let smokerStatusMessage: any = "";
  smokerStatusMessage = "1";

  const lblAddDependentText = ObjectUtils.extractLabelFromObject(contents, "lblAddDependentText");

  const spouseList: any = [];
  const studentList: any = [];

  relationshipListDisplay.map((item: any) => {
    if (item.type === "Spouse") {
      spouseList.push(item.value);
    }
    return null;
  });

  relationshipList.map((item: any) => {
    if (item.type === "Student") {
      studentList.push(item);
    }
    return null;
  });

  const depDetailsObject: any = [];

  //dependentsData?.result?.Dependents
  dependentList?.map((item: any, index: number) => {
    const userRelationText = ObjectUtils.getByValue(dependentsData?.relationshipListDisplay, item?.relationshipID).text;
    const smokerStatus: boolean = !spousalSmokerDeclarationOptional && !smokerDeclarationChanged;
    if (parseInt(item?.smokerStatus) === 0 && smokerStatus) smokerStatusMessage = nonSmokerMessage;
    if (parseInt(item?.smokerStatus) === 1 && smokerStatus) smokerStatusMessage = smokerMessage;
    smokerStatusMessage = nonSmokerMessage;
    const isStudentORChild = item.type == "Student" || item.type == "Child" ? true : false;
    const objSelected = studentValidErrorList?.filter((obj) => obj.dependentID == item?.dependentID && obj.studentValid === true);
    const head: any = {
      gridtitle: item?.fullName,
      showediticon: false,
      showdeleteicon: !readOnly && canBeRemoved,
      deletebtndata: {
        className: "icon-action edit-icon close-icon float-right",
        ariaLabel: deleteIcon?.textToRead + " " + item?.fullName,
        onSubmit: () => showDeleteConfirmModal(item.dependentID)
      }
    };

    const gridRowData: any = {
      gridRowData: [
        [
          {
            type: "select",
            name: "maritalStatus",
            display: lblRelationshipText.display,
            texttoread: lblRelationshipText.textToRead + " " + userRelationText,
            mode: "view",
            selectedvalue: item?.relationshipID,
            value: userRelationText,
            options: dependentsData?.relationshipListDisplay,
            colclassname: "col-sm-12 col-md-6",
            fgclass: "peronal-info-details"
          },
          {
            type: "text",
            name: "dateOfBirth",
            display: lblBirthDateText.display,
            texttoread: lblBirthDateText.textToRead + " " + item?.dateOfBirth?.display,
            mode: "view",
            datetime: item?.dateOfBirth?.display,
            colclassname: "col-sm-12 col-md-6",
            fgclass: "peronal-info-details"
          }
        ],
        [
          {
            type: "select",
            name: "gender",
            display: lblGenderText.display,
            texttoread: lblGenderText.textToRead + " " + item?.gender,
            mode: "view",
            selectedvalue: item?.gender,
            value: item?.gender,
            options: dependentsData?.genderList,
            colclassname: "col-sm-12 col-md-6"
          },
          //Spouse smoker status start
          {
            message: smokerStatusMessage.display,
            messagetype: "",
            colclassname: "col-sm-12 col-md-6",
            skip: spouseList.includes(item.relationshipID.toString()) && spousalSmokerDeclarationOptional && readOnly && !item.isEditableSmokerStatus ? null : "1"
          },
          {
            type: "choicelist",
            colclassname: "col-sm-12 col-md-6",
            mode: "edit",
            skip: spouseList.includes(item.relationshipID.toString()) && !spousalSmokerDeclarationOptional && !readOnly && item.isEditableSmokerStatus ? null : "1",
            options: {
              type: "radio",
              fgclass: "mt-2 mb-4",
              width: 50,
              legendtext: smokerStatusMessage?.display,
              name: `smokerStatus_${index}`,
              selected: item.smokerStatus.toString(),
              dependentID: item.dependentID,
              optionsinput: smokerStatusList,
              handlechange: changeSmokerStatus
            }
          },
          //Spouse smoker status start
          spouseList.includes(item.relationshipID.toString()) &&
            item?.smokerStatus === 0 &&
            !spousalSmokerDeclarationOptional &&
            !(readOnly || !item?.isEditableSmokerStatus) &&
            smokerDeclarationChanged && {
              colclassname: "col-sm-12 col-md-12",
              type: "instructions",
              display: smokerDeclarationLabel,
              classname: "primary mb-3"
            },
          //Spouse smoker status end
          //{Covered this Plan}
          {
            type: "choicelist",
            colclassname: "row",
            mode: "edit",
            skip: item?.isEditableIsCoveredThisPlan ? null : "1",
            options: {
              type: "checkbox",
              ischecked: true,
              fgclass: "mt-2",
              width: 100,
              name: `isCoveredThisPlan_${index}`,
              selected: item.isCoveredThisPlan,
              dependentID: item.dependentID,
              optionsinput: [
                {
                  text: lblCoveredThisPlanCaptionText.display,
                  value: true
                }
              ],
              handlechange: changeCoverUnder
            }
          },
          //{Covered Other Plan}
          {
            skip: item?.isEditableIsCoveredOtherPlan ? null : "1",
            colclassname: "col-sm-12 col-md-6"
          },
          {
            type: "choicelist",
            colclassname: "row",
            mode: "edit",
            skip: item?.isEditableIsCoveredOtherPlan ? null : "1",
            options: {
              type: "checkbox",
              ischecked: true,
              fgclass: "mt-2 mb-4",
              width: 100,
              name: `isCoveredOtherPlan_${index}`,
              selected: item.isCoveredOtherPlan,
              dependentID: item.dependentID,
              optionsinput: [
                {
                  text: lblCoveredOtherPlanCaptionText.display,
                  value: true
                }
              ],
              handlechange: changeCoverUnder
            }
          }
        ],
        !readOnly &&
          isAllowUpdateSudentStatus &&
          item.isChildCanTurnIntoStudent &&
          item.isChildRelationshipEditable && [
            {
              type: "choicelist",
              colclassname: "col-sm-12 col-md-11",
              mode: "edit",
              // skip: !readOnly && isAllowUpdateSudentStatus && item.isChildCanTurnIntoStudent && item.isChildRelationshipEditable ? null : "1",
              options: {
                type: "radio",
                width: 50,
                fgclass: "mt-2 mb-4 w-50 float-left",
                legendtext: lblRelationshipText.display,
                name: `updateDependentRelationshipID_${index}`,
                selected: item.relationshipID,
                dependentID: item.dependentID,
                handlechange: changeRelationID,
                optionsinput: studentList
              }
            }
          ],
        [
          !readOnly &&
            isAllowUpdateSudentStatus &&
            item.isChildCanTurnIntoStudent &&
            ((!item.isChildRelationshipEditable && isStudentORChild) || objSelected?.length > 0) && {
              message: !item.isStudentStatusConfirmed ? lblStudentNeedToRemoveDuringLEorAE.display : "",
              messagetype: "primary",
              rowclassname:
                !readOnly && isAllowUpdateSudentStatus && item.isChildCanTurnIntoStudent && ((!item.isChildRelationshipEditable && isStudentORChild) || objSelected.length > 0) ? "bdr-container" : ""
              // skip: !readOnly && isAllowUpdateSudentStatus && item.isChildCanTurnIntoStudent && studentValid ? null : "1"
            },
          !readOnly &&
            isAllowUpdateSudentStatus &&
            item.isChildCanTurnIntoStudent &&
            ((!item.isChildRelationshipEditable && isStudentORChild) || objSelected.length > 0) && {
              type: "choicelist",
              colclassname: "col-sm-12 col-md-11",
              mode: "edit",
              // skip: !readOnly && isAllowUpdateSudentStatus && item.isChildCanTurnIntoStudent && studentValid ? null : "1",
              options: {
                type: "checkbox",
                ischecked: true,
                width: 100,
                fgclass: "mt-2 mb-4",
                name: `isStudentStatusConfirmed_${index}`,
                selected: item.isStudentStatusConfirmed,
                dependentID: item.dependentID,
                optionsinput: [
                  {
                    text: lblStudentDeclarationText.display,
                    value: true
                  }
                ],
                handlechange: changeStudentStatus
              }
            }
        ]
      ]
    };

    const footer: any = {
      showFooter: false
    };

    depDetailsObject.push(
      <div className="row">
        <div className="w-100">
          <CardComponent
            key={`d-div-${item?.dependentID}-${index}`}
            gridObject={{
              head,
              body: gridRowData,
              footer: footer
            }}
          ></CardComponent>
        </div>
      </div>
    );
  });
  const modalObject = {
    showModal: showRemoveConfirmModal,
    clickHandler: handleClose,
    dialogClass: "modal-lg",
    head: {
      modalTitle: lblDeleteDependentHeader.display
    },
    body: {
      confirmBoxData: {
        messagetitle: lblDeleteDependentConfirmation.display,
        messagetitleclass: "mb-2 fw-bold",
        messagetext: lblDeleteDependentConfirmation2.display,
        messagetextclass: "ml-popupmessagetext align-left"
      }
    },
    footer: {
      footerData: [
        {
          variant: "outline-secondary btn-cancel",
          display: btnCancel?.display,
          onSubmit: handleClose
        },
        {
          variant: "outline-secondary",
          display: lblDeleteDependentConfirmBtn?.display,
          onSubmit: handleDeleteDependent
        }
      ]
    }
  };

  const handleAddNewDependentModel = () => {
    setShowAddNewDependentModal(true);
  };

  const addNewDependent = [
    {
      variant: "outline-secondary btn-addnewdependent float-right",
      display: lblAddDependentText.display,
      onSubmit: handleAddNewDependentModel
    }
  ];

  const closeAddNewDependentModel = () => {
    setShowAddNewDependentModal(false);
  };

  const modalAddNewDependent = {
    showModal: showAddNewDependentModal,
    clickHandler: closeAddNewDependentModel,
    dialogClass: "ml-addnewdependent",
    head: {
      modalTitle: lblAddDependentText.display
    }
  };

  return (
    <>
      {dependentsData ? (
        <>
          {dependentList && !dependentList?.length && readOnly && <AlertComponent propmessageType={`light`} propmessage={lblNoDependentsText.display} />}
          <div className="row">
            {!readOnly && lblYoucanaddordeleteyourdependent.display && <AlertComponent propmessageType={`light`} propmessage={lblYoucanaddordeleteyourdependent.display} />}

            <div className="row">
              <div className="container">
                {depDetailsObject}

                {!(nextEnrolStep.navigationError === undefined || nextEnrolStep.navigationError === null) && (
                  <AlertComponent propmessageType={`danger mb-4`} propmessage={nextEnrolStep?.navigationError} />
                )}
              </div>
            </div>

            {!readOnly && dependentType === "" && (
              <div className="ml-popupbuttons clearfix w-100 px-3">
                <ButtonComponent btndata={addNewDependent} />
              </div>
            )}
            {<ModalComponent modalObject={modalObject} />}
            {!readOnly && (
              <ModalComponent modalObject={modalAddNewDependent}>
                <AddNewDependent
                  contents={contents}
                  dependents={dependentsData}
                  contentFooter={contentFooter}
                  contents1={contents1}
                  closeAddNewDependentModel={closeAddNewDependentModel}
                  canSpouseAdded={canSpouseAdded}
                />
              </ModalComponent>
            )}
          </div>
        </>
      ) : null}
    </>
  );
};

export default Dependents;
